import React from 'react';
import { Link, Text } from './';
import { FormattedMessage } from 'react-intl';

export const Footer = props => {

    return (
        <footer className="footer bg-repeat relative pt-1 bg-footer-texture">
            {!props.small &&
                <div className="container mx-auto px-6">
                    <div className="sm:flex sm:mt-8">
                        <div className="mt-8 sm:mt-0 sm:w-full sm:px-8 flex flex-col md:flex-row justify-between">
                            <div className="flex flex-col">
                                <Text label="sv.footer.nav" className="font-bold text-gray-700 uppercase mt-4 md:mt-0 mb-2" />
                                <span className="my-2">
                                    <Link to="/news" className="text-gray-500 text-md hover:text-blue-500" label="sv.footer.news" />
                                </span>
                                <span className="my-2">
                                    <Link to="/signup" className="text-gray-500 text-md hover:text-blue-500" label="sv.footer.doctors" />
                                </span>
                                <span className="my-2">
                                    <Link to="/signupdoctor" className="text-gray-500 text-md hover:text-blue-500" label="sv.footer.for_cooperation" />
                                </span>
                                <span className="my-2">
                                    <Link to="/pricing" className="text-gray-500 text-md hover:text-blue-500" label="sv.footer.pricing" />
                                </span>
                            </div>
                            <div className="flex flex-col">
                                <Text label="sv.footer.social" className="font-bold text-gray-700 uppercase mt-4 md:mt-0 mb-2" />
                                <span className="my-2"><Link external to="https://www.facebook.com/soulvirtuee"
                                                          className="text-gray-500 text-md hover:text-blue-500">Facebook</Link></span>
                                <span className="my-2"><Link external to="https://www.instagram.com/soulvirtue.lv/"
                                                          className="text-gray-500  text-md hover:text-blue-500">Instagram</Link></span>
                            </div>
                            <div className="flex flex-col">
                                <Text label="sv.footer.agreements" className="font-bold text-gray-700 uppercase mt-4 md:mt-0 mb-2" />
                                <Link to="/privacy" className="my-2"><FormattedMessage id="sv.footer.privacy_policy" /></Link>
                                <Link to="/terms" className="my-2"><FormattedMessage id="sv.footer.service_terms" /></Link>
                            </div>
                        </div>
                    </div>
                </div>
            }
            <div className="container mx-auto px-6">
                <div className="mt-16 border-gray-300 flex flex-col items-center">
                    {/*<hr className="h-1 w-full" />*/}
                    <div className="sm:w-2/3 text-center py-6">
                        <p className="text-sm text-gray-700 mb-2">
                            <Text label="sv.medical_institution_number" />
                        </p>
                        <p className="text-sm text-gray-700 mb-2">
                            <Text label="sv.contact_tel_number" />&nbsp;<b><a href="tel:+371 27726366">+371 27726366</a></b>&nbsp;
                            <Text label="sv.contact_email" />&nbsp;<b><a href="mailto:soulvirtue@soulvirtue.lv">soulvirtue@soulvirtue.lv</a></b>
                        </p>
                        <p className="text-sm text-gray-700 font-bold mb-2">
                            © {(new Date().getFullYear())} SoulVirtue
                        </p>
                    </div>
                </div>
            </div>
        </footer>
    );
};
