import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { useField } from 'formik';
import { FormError, FormLabel } from './';


export const FormCheckbox = props => {

    const intl = useIntl();
    const [field, meta] = useField(props.name);

    return(
        <div className={(props.className || '')}>
            <div className={(props.marginTop ? props.marginTop : 'mt-4')}>
                {props.labelPosition === 'left' &&
                    <FormLabel label={props.label} name={props.name} className="inline-block mr-2"/>
                }
                <input
                    checked={field.value}
                    {...field}
                    type="checkbox"
                    name={props.name}
                    id={props.name}
                    placeholder={intl.formatMessage({id: props.placeholder})}
                    className={'w-5 h-5 rounded-sm shadow-sm focus:ring-sky-600 focus:border-sky-500 inline-block' +
                    ' shadow-sm sm:text-sm border-gray-300 ' + (meta.touched && meta.error && 'border-red-500 focus:border-red-600 focus:ring-red-600')}
                />
                {(props.labelPosition === 'right' || !props.labelPosition) &&
                    <FormLabel label={props.label} name={props.name} className="inline-block ml-2"/>
                }
            </div>
            <FormError meta={meta} />
        </div>
    );
};

FormCheckbox.propTypes = {
    name: PropTypes.string.isRequired,
    placeholder: PropTypes.string.isRequired,
    marginTop: PropTypes.string,
    label: PropTypes.string,
    className: PropTypes.string,
};
