import { userConstants } from '../_constants';


const initialState = {
    loggedIn: false,
    synchronized: false,
    email: '',
    name: '',
    surname: '',
    role: 'guest',
    status: 'created'
};

export function user(state = Object.assign({}, initialState), action) {
    switch (action.type) {
        case userConstants.LOAD_SETTINGS_SUCCESS:
            state.loggedIn = true;
            state.synchronized = true;

            return Object.assign({}, {...state, ...action.data, role: action.data.currentClient.role,
                currentClient: action.data.currentClient});
        case userConstants.LOAD_SETTINGS_FAILURE:
            return Object.assign({...state}, action.data);
        case userConstants.RESET_SETTINGS:
            return Object.assign({}, {...initialState, synchronized: true});
        case userConstants.SWITCH_CLIENT_SUCCESS:
            return Object.assign({}, {...state, ...action.data, role: action.data.currentClient.role,
                currentClient: action.data.currentClient});
        case userConstants.UPDATE_PAYMENT_PLAN:
            const currentClientIdx = state.clients.findIndex(c => c._id._id === state.currentClient._id);

            if (currentClientIdx !== -1) {
                state.clients[currentClientIdx]._id.paymentPlan = action.plan;
            }

            return Object.assign({}, state);
        default:
            return state;
    }
}
