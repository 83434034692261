import React from 'react';


export const AuthFormWrap = props => (
    <div className="min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
        <div className="max-w-md w-full space-y-4 bg-white p-8 rounded border-gray-300 border">
            {props.children}
        </div>
    </div>
);
