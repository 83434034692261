import React from 'react';
import PropTypes from 'prop-types';
import { ErrorMessage } from 'formik';
import { InlineDatepicker } from './InlineDatepicker';


export const FormInlineDatepicker = props => {

    return (
        <>
            <InlineDatepicker date={props.date}
                              label={props.label}
                              name={props.name}
                              dateIsBirthDate={props.dateIsBirthDate}
                              displayYearAsc={props.displayYearAsc}
                              noTopMargin={props.noTopMargin}
                              withTime={props.withTime}
                              smallHeight={props.smallHeight}
                              futureDate={props.futureDate}
                              onChange={val => props.onChange(props.name, val, true)} />
            <ErrorMessage className="infir-input-error-label" name={props.name} component="div" />
        </>);
};

FormInlineDatepicker.propTypes = {
    name: PropTypes.string.isRequired,
    placeholder: PropTypes.string.isRequired,
    date: PropTypes.string,
    label: PropTypes.string,
    className: PropTypes.string,
    noTopMargin: PropTypes.bool,
    dateIsBirthDate: PropTypes.bool,
    smallHeight: PropTypes.bool,
    withTime: PropTypes.bool
};
