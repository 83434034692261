import React, { Suspense } from 'react';
import { Text, Loader } from './';


export const LoadingWithSuspense = (Component, props) => {

    const SuspenseComponent = <Suspense fallback={
        <div className="mt-3">
            <Loader />
        </div>
    }>
        <Component {...props} match={props.match} />
    </Suspense>;

    return (
        <>{SuspenseComponent}</>
    );
};
