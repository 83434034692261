import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from "react-intl";


export const FormLabel = (props) => (
    <>
        {props.label &&
        <label htmlFor={props.name}
               className={'block text-sm font-small ' + (props.className ? props.className + ' ' : '') + (props.labelPosition ?
                   props.labelPosition === 'left' ? 'text-right mr-2' : 'text-left ml-2' :
                   'text-left mb-2')}>
            <FormattedMessage id={props.label}/>
        </label>
        }
    </>
);

FormLabel.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    labelPosition: PropTypes.oneOf(['left', 'right']),
    className: PropTypes.string
};
