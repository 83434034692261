import React from 'react';
import { Collapse, Text } from './';
import { UserCircleIcon } from '@heroicons/react/solid';
import PropTypes from 'prop-types';
import langs from '../languages.json';
import { useIntl } from 'react-intl';
import { history } from '../_library';
import {useSelector} from "react-redux";


export const DoctorInfo = props => {

    const intl = useIntl();
    const { lang } = useSelector(state => state.i18n);

    const specialities = {
        'psychologist': intl.formatMessage({id: 'sv.doctor_specialities.psychologist'}),
        'psychiatrist': intl.formatMessage({id: 'sv.doctor_specialities.psychiatrist'}),
        'psychotherapist': intl.formatMessage({id: 'sv.doctor_specialities.psychotherapist'}),
        'therapist': intl.formatMessage({id: 'sv.doctor_specialities.therapist'}),
        'narcologist': intl.formatMessage({id: 'sv.doctor_specialities.narcologist'}),
        'physiotherapist': intl.formatMessage({id: 'sv.doctor_specialities.physiotherapist'}),
        'nutrition_specialist': intl.formatMessage({id: 'sv.doctor_specialities.nutrition_specialist'})
    };

    return (
        <>
            <div className={(props.small ? 'cursor-pointer hidden' : 'md:hidden')} onClick={() => (props.small ? history.push('/signup') : {})}>
                <Collapse
                    white
                    title={
                        <div className="w-full min-h-40 flex justify-between p-2 items-center">
                            <div className="flex-none w-12 flex items-center">
                                {props.photo &&
                                    <img src={props.photo} className="rounded-full border h-12 w-12 object-cover object-top"/>
                                }
                                {!props.photo &&
                                    <UserCircleIcon className="rounded-full text-gray-300 h-14 w-14"/>
                                }
                            </div>
                            <div className="ml-2 flex-grow">
                                <div>
                                    <Text className="font-bold mb-1 text-base">
                                        {props.name && props.name}
                                        {!props.name && <Text label="sv.doctor_info.placeholder.name"/>}
                                        &nbsp;
                                        {props.surname && props.surname}
                                        {!props.surname && <Text label="sv.doctor_info.placeholder.surname"/>}
                                    </Text>
                                    {(!props.small ?
                                        <div>
                                            <span className="bg-sv-p rounded p-0.5 pl-1 pr-1 text-white">
                                                <Text className="text-xs">
                                                    {props.speciality && specialities[props.speciality]}
                                                    {!props.speciality && <Text label="sv.doctor_info.placeholder.speciality"/>}
                                                </Text>
                                            </span>
                                            {props.langs &&
                                                <>
                                                    {props.langs.map((lang) =>
                                                            <span className="bg-sv-s rounded p-0.5 ml-2 pl-1 pr-1 text-sv-p">
                                                                <Text className="text-xs">
                                                                    {langs.find(l => l.value === lang).value.toUpperCase()}
                                                                </Text>
                                                            </span>
                                                    )}
                                                </>
                                            }
                                        </div> : <></>
                                    )}
                                </div>
                            </div>
                            {(!props.small ?
                                <div className="rounded bg-sv-s p-2 flex-none h-16 flex items-center justify-center ml-3">
                                    <div>
                                        <Text className="text-xl">
                                            {props.price && props.price}
                                            {!props.price && <Text label="sv.doctor_info.placeholder.price"/>}
                                        </Text>&nbsp;
                                        <Text>&euro;/h</Text>
                                    </div>
                                </div> : <></>)
                            }
                            {(props.small ?
                                <>
                                    <div>
                                        <span className="bg-sv-p rounded p-0.5 pl-1 pr-1 text-white">
                                            <Text className="text-xs">
                                                {props.speciality && specialities[props.speciality]}
                                                {!props.speciality && <Text label="sv.doctor_info.placeholder.speciality"/>}
                                            </Text>
                                        </span>
                                    </div>
                                    <div>
                                        {props.langs &&
                                            <>
                                                {props.langs.map((lang) =>
                                                    <span className="bg-sv-s rounded p-0.5 ml-2 pl-1 pr-1 text-sv-p">
                                                        <Text className="text-xs">
                                                            {langs.find(l => l.value === lang).value.toUpperCase()}
                                                        </Text>
                                                    </span>
                                                )}
                                            </>
                                        }
                                    </div>
                                </> : <></>
                            )}
                        </div>
                    }
                    content={
                        <div>
                            {(!props.small ?
                                <>
                                    {lang === 'ru' ?
                                        <>{props.bioRu || <Text label="sv.doctor_info.placeholder.bio"/>}</>
                                        : lang === 'lv' ?
                                            <>{props.bioLv || <Text label="sv.doctor_info.placeholder.bio"/>}</>
                                            :
                                            <>{props.bioEn || <Text label="sv.doctor_info.placeholder.bio"/>}</>
                                    }
                                </> : <></>
                            )}
                        </div>
                    }
                />
            </div>
            <div className={'w-full rounded border mr-10 min-h-40 p-5 ' + (props.small ? 'cursor-pointer ' : '') + (!props.bigImage ? 'hidden md:block' : 'block')}
                 onClick={() => (props.small ? history.push('/signup') : {})}>
                {props.bigImage &&
                    <div className="flex items-center justify-center">
                        {props.photo &&
                            <img src={props.photo} className="rounded-full border h-48 w-48 object-cover object-top"/>
                        }
                        {!props.photo &&
                            <UserCircleIcon className="rounded-full text-gray-300 h-48 w-48"/>
                        }
                    </div>
                }
                <div className={'items-center ' + (props.small ? 'flex' : 'hidden md:flex')}>
                    {!props.bigImage &&
                        <div className="flex-none w-20 flex items-center">
                            {props.photo &&
                                <img src={props.photo} className="rounded-full border h-20 w-20 object-cover object-top"/>
                            }
                            {!props.photo &&
                                <UserCircleIcon className="rounded-full text-gray-300 h-20 w-20"/>
                            }
                        </div>
                    }
                    <div className={'flex-grow ' + (props.bigImage ? '' : 'ml-5')}>
                        <div>
                            <Text className="font-bold mb-1 text-lg" center={props.bigImage}>
                                {props.name && props.name}
                                {!props.name && <Text label="sv.doctor_info.placeholder.name"/>}
                                &nbsp;
                                {props.surname && props.surname}
                                {!props.surname && <Text label="sv.doctor_info.placeholder.surname"/>}
                            </Text>
                            {(!props.small ?
                                <>
                                    <span className="bg-sv-p rounded p-0.5 pl-1 pr-1 ml-2 text-white">
                                        <Text className="text-xs">
                                            {props.speciality && specialities[props.speciality]}
                                            {!props.speciality && <Text label="sv.doctor_info.placeholder.speciality"/>}
                                        </Text>
                                    </span>
                                    {props.langs &&
                                        <>
                                            {props.langs.map((lang, idx) =>
                                                <span key={'spec_' + idx.toString()} className={'bg-sv-s rounded p-0.5 pl-1 pr-1 text-sv-p ml-2'}>
                                                    <Text className="text-xs">
                                                        {langs.find(l => l.value === lang).value.toUpperCase()}
                                                    </Text>
                                                </span>
                                            )}
                                        </>
                                    }
                                </> : <></>
                            )}
                            {(props.small && props.bigImage) ?
                                <div className="flex justify-center">
                                    <span className="bg-sv-p rounded p-0.5 pl-1 pr-1 ml-2 text-white">
                                        <Text className="text-xs">
                                            {props.speciality && specialities[props.speciality]}
                                            {!props.speciality && <Text label="sv.doctor_info.placeholder.speciality"/>}
                                        </Text>
                                    </span>
                                    {props.langs &&
                                    <>
                                        {props.langs.map((lang, idx) =>
                                          <span key={'spec_' + idx.toString()} className={'bg-sv-s rounded p-0.5 pl-1 pr-1 text-sv-p ml-2'}>
                                                    <Text className="text-xs">
                                                        {langs.find(l => l.value === lang).value.toUpperCase()}
                                                    </Text>
                                                </span>
                                        )}
                                    </>
                                    }
                                </div> : <></>
                            }
                        </div>
                        {((props.small && !props.bigImage) ?
                            <>
                                <div>
                                    <span className="bg-sv-p rounded p-0.5 pl-1 pr-1 text-white">
                                        <Text className="text-xs">
                                            {props.speciality && specialities[props.speciality]}
                                        </Text>
                                    </span>
                                </div>
                                <div>
                                    {props.langs &&
                                        <>
                                            {props.langs.map((lang, idx) => (
                                                    <span className={'bg-sv-s rounded p-0.5 pl-1 pr-1 text-sv-p ' + (idx > 0 ? 'ml-2' : '')}>
                                                        <Text className="text-xs">
                                                            {langs.find(l => l.value === lang).value.toUpperCase()}
                                                        </Text>
                                                    </span>
                                                )
                                            )}
                                        </>
                                    }
                                </div>
                            </> : <></>
                        )}
                        {(!props.small ?
                            <div className="mt-2 text-sm">
                                <Text left>
                                    {(!props.small ?
                                            <>
                                                {lang === 'ru' ?
                                                    <>{props.bioRu || <Text label="sv.doctor_info.placeholder.bio"/>}</>
                                                    : lang === 'lv' ?
                                                        <>{props.bioLv || <Text label="sv.doctor_info.placeholder.bio"/>}</>
                                                        :
                                                        <>{props.bioEn || <Text label="sv.doctor_info.placeholder.bio"/>}</>
                                                }
                                            </> : <></>
                                    )}
                                </Text>
                            </div> : <></>
                        )}
                    </div>
                    {(!props.small ?
                        <div className="rounded bg-sv-s p-3 lg:p-5 h-20 flex-none flex items-center justify-center ml-3">
                            <div>
                                <Text className="text-3xl">
                                    {props.price && props.price}
                                    {!props.price && <Text label="sv.doctor_info.placeholder.price"/>}
                                </Text>&nbsp;
                                <Text>&euro;/h</Text>
                            </div>
                        </div> : <></>)
                    }
                </div>
            </div>
        </>
    );
};

DoctorInfo.propTypes = {
    name: PropTypes.string,
    surname: PropTypes.string,
    photo: PropTypes.string,
    bioEn: PropTypes.string,
    bioLv: PropTypes.string,
    bioRu: PropTypes.string,
    price: PropTypes.number,
    speciality: PropTypes.string,
    langs: PropTypes.array
};
