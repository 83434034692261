import React, { Fragment, useRef } from 'react';
import { Dialog, Transition } from '@headlessui/react'
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';


export const Modal = props => {

    let modalFocusBtnRef = useRef(null);

    return (
        <Transition appear show={props.isOpen} as={Fragment}>
            <Dialog
                as="div"
                className={'fixed inset-0 z-30 overflow-y-auto ' + (props.className && props.className)}
                onClose={props.toggle}
                initialFocus={modalFocusBtnRef}
            >
                <div className="min-h-screen px-4 text-center">
                        <Dialog.Overlay className="fixed inset-0 bg-sky-500 opacity-30" />

                    {/* This element is to trick the browser into centering the modal contents. */}
                    <span
                        className="inline-block h-screen align-middle"
                        aria-hidden="true"
                    >
              &#8203;
            </span>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 scale-95"
                        enterTo="opacity-100 scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95"
                    >
                        <div className={'inline-block w-full p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl border rounded-2xl ' + (props.big ? 'max-w-2xl' : (props.xl ? 'max-w-6xl' : 'max-w-md'))}>
                            <Dialog.Title
                                as="h3"
                                className="text-lg font-medium leading-6 text-gray-900 mb-5"
                            >
                                <FormattedMessage id={props.title} />
                            </Dialog.Title>
                            {props.children && props.children}
                            {props.content}
                            <button ref={modalFocusBtnRef} className="invisible" />
                        </div>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition>
    );
};

Modal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    toggle: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
    content: PropTypes.element.isRequired,
    big: PropTypes.bool,
    className: PropTypes.string
};
