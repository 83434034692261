import { chatConstants } from '../_constants';


const initialState = {
    messages: []
};

export function chat(state = Object.assign({}, initialState), action) {
    const newMessages = state.messages.slice();

    switch (action.type) {
        case chatConstants.ADD_MESSAGE:

            newMessages.push(action.data.message);

            return Object.assign({}, {...state, messages: newMessages});
        case chatConstants.REMOVE_MESSAGE:

            const idx = newMessages.findIndex(m => m._id === action.data.messageId);

            if (idx !== -1) {
                newMessages.splice(idx, 1);
                return Object.assign({}, {...state, messages: newMessages});
            }

            return state;
        default:
            return state;
    }
}
