import config from '../config';
import { chatActions, consultationActions } from '../_actions';
import { store } from './';


export let socket = null;

export const openWSConnection = () => {
    if (!socket && localStorage.getItem(config.accessTokenName)) {
        socket = new WebSocket(config.wsBaseUrl + '/' + localStorage.getItem(config.accessTokenName));
        socket.onmessage = handleSocketMessage;
        socket.onclose = () => {
            socket = null;
            // setTimeout(() => {
                openWSConnection();
            // }, 5000);
        };
    }
};

const handleSocketMessage = msg => {
    const data = JSON.parse(msg.data);

    if (data.event === 'chatMessage') {
        store.dispatch(chatActions.addMessage(data.message));
    } else if (data.event === 'partnerJoinedCall') {
        store.dispatch(consultationActions.setPartnerSignal(data.message));
    } else if (data.event === 'callStarted') {
        store.dispatch(consultationActions.setCallStarted(data.message));
    } else if (data.event === 'callFinished') {
        store.dispatch(consultationActions.setCallFinished(data.message));
    }
};
