import React, { useEffect, useRef, useState } from 'react';
import { Table } from './index';
import { FormCategoryFilter } from "./FormCategoryFilter";
import { useHistory } from "react-router";
import PropTypes from "prop-types";


export const TableWithPagination = props => {

    const history = useHistory();

    const [rows, setRows] = useState([]); // Array of row data
    let limit = useRef(50);
    let total = useRef(0);
    let offset = useRef(-1);

    const defaults = props.defaults || {limit: 50, offset: 0}; // These defaults will always be attached to query >> IF NOT SPECIFIED <<
    let queryObj = useRef(defaults); // Keeping a reference to query object

    // URLSearchParams ignores undefined params, otherwise empty query params are showed for "" values
    // "defaults" will be always added
    // Could add an array of ignored values later, like ["All"]
    function cleanQueryObj(obj, defaults = {}){
        Object.keys(obj).forEach(key => {
            if (obj[key] === "") {
                delete obj[key]
            }
        });

        let temp = {};

        Object.keys(defaults).forEach(key => { // Adding defaults if they already don't exist
            if (!(key in obj)){
                temp[key] = defaults[key]
            }
        });

        if(Object.keys(temp).length !== 0) {   // Adding the defaults to the beginning
            obj = {...temp, ...obj};
        }

        return obj;
    }

    // Initial setup
    useEffect(() => {
        let initialQueryObj = Object.fromEntries(new URLSearchParams(history.location.search)); // Getting query object from URL
        queryObj.current = cleanQueryObj(initialQueryObj, defaults);                            // Cleaning this query object
        let queryString = new URLSearchParams(queryObj.current).toString();                     // Getting query string from the cleaned queryObj
        history.replace({search: queryString});                                                 // Updating history
                                                                                                // func setPage(), handleSubmit() is similar
        search(queryString).then()
    }, []);

    useEffect(() => {
        if (props.updateData && props.updateData === true) {
            search('');
        }
    }, [props.updateData]);

    // Getting data using queryString
    // props.getData is required
    // props.getData should be an api call that returns promise
    function search(queryString) {
        return props.getData(queryString).then(res => {
            limit.current = res.data.limit;
            total.current = res.data.total;
            offset.current = res.data.offset;

            setRows(res.data.data);
        }).catch(error => { // NOT FOUND or other errors
            total.current = 0;
            offset.current = -1;

            setRows([]);
        });
    }

    // Submit function from FormCategoryFilter
    function handleSubmit(value, actions){
        queryObj.current = cleanQueryObj(Object.assign({}, value), defaults);
        let queryString = new URLSearchParams(queryObj.current).toString();
        history.push({search: queryString});

        search(queryString).then(() => {
            actions.setSubmitting(false);
        }, () => { // ERROR
            actions.setSubmitting(false);
        });
    }

    // Reset function from FormCategoryFilter
    function handleReset(){
        let queryString = new URLSearchParams(defaults).toString();
        history.push({search: queryString});

        search('').then();
    }

    // Navigation button for table
    function setPage(page) {
        queryObj.current.offset = page;
        let queryString = new URLSearchParams(queryObj.current).toString();
        history.push({search: queryString});

        search(queryString);
    }

    return (
        <div>
            <div className={"min-h-screen " + (props.noBackground ? '' : 'bg-gray-200')}>
                {props.catFilter &&
                    <FormCategoryFilter
                        initValues={props.initValues}
                        onSubmit={handleSubmit}
                        onReset={handleReset}
                        fields={props.inputFields}
                        submitBtnLabel="sv.admin_patients.filter.search"
                        resetBtnLabel="sv.admin_patients.filter.reset_btn"
                    />
                }
                {props.additionalButton}
                <Table
                    pagination={true}
                    columns={props.cols}
                    statuses={props.statuses}
                    rows={rows}
                    total={total.current}
                    page={offset.current}
                    limit={limit.current}
                    setPage={setPage}
                    actions={props.actions}
                    additionalButton={props.additionalButton}
                />
            </div>
        </div>
    );
};

TableWithPagination.protoTypes = {
    cols: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string,
        label: PropTypes.string,
        width: PropTypes.string

    })).isRequired,
    getData: PropTypes.func.isRequired,             //getData should be function that returns a promise with response -
    catFilter: PropTypes.bool,                      // ^^^ - like {data: {offset: ..., limit: ..., total: ..., data...}, ...}
    updateData: PropTypes.bool,
    initValues: PropTypes.object,  //from <Form /> for categoryFilter.
    inputFields : PropTypes.object, //from <Form /> for categoryFilter. NOTE: inputField value names have to be identical to database model
    noBackground : PropTypes.string,
    additionalButton : PropTypes.object,
    actions: PropTypes.arrayOf(PropTypes.shape({ // Action buttons, multiple. ALSO, if using cols.width (%) then leave some % for this row
        onClick: PropTypes.func.isRequired,           // 3 rows each width 1/4 would leave 1/4 for actions row
        label: PropTypes.string.isRequired
    })),
};

