import React from 'react';
import { Disclosure, Transition } from '@headlessui/react';
import { ChevronUpIcon } from '@heroicons/react/solid';
import PropTypes from 'prop-types';


export const Collapse = props => (
    <Disclosure>
        {({ open }) => (
            <React.Fragment {...(props.idx !== undefined ? {idx: props.idx} : {})} >
                <Disclosure.Button className={'md:flex justify-between w-full px-1 md:px-4 py-2 text-sm font-medium text-left rounded-lg focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75 ' + (props.white ? 'bg-white border' : 'text-purple-900 bg-purple-100 hover:bg-purple-200')}>
                    <div className="flex-grow">{props.title}</div>
                    <ChevronUpIcon
                        className={`${
                            open ? '' : 'transform rotate-180'
                        } w-5 h-5 text-purple-500 mx-auto md:mx-0`}
                    />
                </Disclosure.Button>
                <Transition
                    show={open}
                    enter="transition duration-100 ease-out"
                    enterFrom="transform scale-95 opacity-0"
                    enterTo="transform scale-100 opacity-100"
                    leave="transition duration-75 ease-out"
                    leaveFrom="transform scale-100 opacity-100"
                    leaveTo="transform scale-95 opacity-0"
                >
                    <Disclosure.Panel className={'px-4 pt-4 pb-2 text-sm ' + (props.white ? '' : 'text-gray-500')}>
                        {props.content}
                    </Disclosure.Panel>
                </Transition>
            </React.Fragment>
        )}
    </Disclosure>
);

Collapse.protoTypes = {
    title: PropTypes.elementType.isRequired,
    content: PropTypes.elementType.isRequired
};
