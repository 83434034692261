import React from 'react';
import { Formik, Form as FForm } from 'formik';
import { Button, FormInput, FormSelect, FormCheckbox } from './';
import PropTypes from 'prop-types';


export const FormCategoryFilter = props => {

    const InputClasses = (props.inputClassName ? props.inputClassName : "inline-block flex-initial mb-5 ml-5 mr-5");

    return(
        <Formik
            enableReinitialize={props.enableReinitialize}
            initialValues={props.initValues}
            onSubmit={props.onSubmit}
            onReset={props.onReset}
        >
            {({ isSubmitting, dirty, isValid }) => (
                <FForm autoComplete="off" onChange={e => props.onChange ? props.onChange(e) : ''}>
                    <div className={'bg-white ' + (props.className ? props.className : 'py-5')}>
                        <div className="flex flex-row-reverse flex-wrap whitespace-nowrap">
                            {props.fields.map((field, idx) => {
                                    switch (field.type) {
                                        case 'checkbox':
                                            return <FormCheckbox key={idx} {...field} className={InputClasses} marginTop="mt-1.5"/>;
                                        case 'select':
                                            return <FormSelect key={idx} {...field} className={InputClasses}/>;
                                        default:
                                            return <FormInput key={idx} {...field} className={InputClasses}/>;
                                    }
                                }
                            )}
                        </div>
                        {(props.submitBtnLabel || props.resetBtnLabel) &&
                            <div className={'flex space-x-5 flex-row-reverse px-5 ' + (props.cancelBtn ? 'justify-between' : '')}>
                                {props.submitBtnLabel &&
                                    <Button type="submit" small="true" className="ml-5" color={'green'}
                                            icon={props.submitBtnIcon} label={props.submitBtnLabel}
                                            disabled={isSubmitting}/>
                                }
                                {props.resetBtnLabel &&
                                <Button type="reset" small="true" className="ml-5" color={'red'} icon={props.resetBtnIcon}
                                        label={props.resetBtnLabel} disabled={isSubmitting}
                                        onClick={(e) => e.currentTarget.blur()}/>
                                }
                            </div>
                        }
                    </div>
                </FForm>
            )}
        </Formik>
    );
};

FormCategoryFilter.propTypes = {
    initValues: PropTypes.object.isRequired,
    fields: PropTypes.arrayOf(PropTypes.object).isRequired,
    onSubmit: PropTypes.func,
    submitBtnLabel: PropTypes.string,
    submitBtnIcon: PropTypes.string,
    resetBtnIcon: PropTypes.string,
    resetBtnLabel: PropTypes.string,
    enableReinitialize: PropTypes.bool,
    className: PropTypes.string,
    inputClassName: PropTypes.string
};
