import React, { useEffect, useRef, useState } from 'react';
import { Button, Form, QuestionnaireForm } from '../components';
import PropTypes from 'prop-types';
import * as Yup from "yup";
import { FormattedMessage } from "react-intl";
import { api } from "../_library";


export const QuestionnaireEdit = (props) => {

    const [questionnaireConfig, setQuestionnaireConfig] = useState([]);
    const [lang, setLang] = useState('lv')

    useEffect(() => {
        props.data()
            .then((res) => {
                const temp = [];
                res.data['questionnaire'].forEach(el => {
                    temp.push({
                        key: new Date().getTime(),
                        question: el.question,
                        editMode: true,
                        multiselect: el.multiselect,
                        lastSelectedIdx: el.lastSelectedIdx,
                        lastSelectedPreEditIdx: -1,
                        options: el.options.map(op => {
                            return {label: op.label, correct: op.correct, selected: op.correct, preEdit: false, categories: op.categories, categoriesSelected: false}
                        })
                    })
                    setQuestionnaireConfig([...temp]);
                });
            }).catch(error => {
            if (error.response.status === 404) {
                newQuestion();
            }
            // TODO: Arturs: some kind of error message?
        });
    }, []);

    const changeLange = (lang) => {
        setLang(lang);
    }

    // When clicking the categories box under the question
    // un-marking logic so there aren't more than 1 selected box at a time
    const selectedArr = useRef([]);
    const lastSelected = useRef([]);
    const categoriesSelectedFunc = (questionIdx, quizIdx) => {
        selectedArr.current = questionnaireConfig[quizIdx].options[questionIdx].categories;
        if (lastSelected.current.length < 1){
            questionnaireConfig[quizIdx].options[questionIdx].categoriesSelected = true;
            updateForm({...questionnaireConfig[quizIdx]}, quizIdx);
            lastSelected.current = [quizIdx, questionIdx];
        } else if (lastSelected.current[0] === quizIdx && lastSelected.current[1] === questionIdx) {
            selectedArr.current = [];
            questionnaireConfig[lastSelected.current[0]].options[lastSelected.current[1]].categoriesSelected = false;
            updateForm({...questionnaireConfig[lastSelected.current[0]]}, lastSelected.current[0]);
            lastSelected.current = [];
        } else {
            if (questionnaireConfig[lastSelected.current[0]] && questionnaireConfig[lastSelected.current[0]].options[lastSelected.current[1]]) {
                questionnaireConfig[lastSelected.current[0]].options[lastSelected.current[1]].categoriesSelected = false;
                updateForm({...questionnaireConfig[lastSelected.current[0]]}, lastSelected.current[0]);
            }
            questionnaireConfig[quizIdx].options[questionIdx].categoriesSelected = true;
            updateForm({...questionnaireConfig[quizIdx]}, quizIdx);
            lastSelected.current = [quizIdx, questionIdx];
        }
    }

    // Updating form option with new categories
    // using latest selected category box
    const categoriesUpdatedForQuestion = (arrOfCats) => {
        let quiz = {...questionnaireConfig[lastSelected.current[0]]};
        quiz.options[lastSelected.current[1]].categories = arrOfCats;
        updateForm(quiz, lastSelected.current[0]);
    }

    const saveQuestionnaireToDB = e => {
        e.target.disabled = true;
        props.save(questionnaireConfig)
            .then(() => {
                e.target.disabled = false;
                e.target.blur();
            }).catch(error => {
            if (error.response.data.error) {
                e.target.disabled = false;
                e.target.blur();
                // TODO: Arturs: some kind of error message?
            }
        });
    }

    const updateForm = (form, idx) => {
        setQuestionnaireConfig(questionnaireConfig => {
            questionnaireConfig[idx] = form
            return [...questionnaireConfig];
        })
    }

    const newQuestion = () => {
        questionnaireConfig.push({});
        updateForm({
            key: new Date().getTime(),
            question: {en: '', ru: '', lv: ''},
            editMode: true,
            multiselect: false,
            lastSelectedIdx: -1,
            lastSelectedPreEditIdx: -1,
            options: [
                {label: {en: '', ru: '', lv: ''}, correct: false, selected: false, preEdit: false, categories: [], categoriesSelected: false }
            ]
        }, questionnaireConfig.length - 1);
    }

    const changeOrder = (idx, order) => {
        setQuestionnaireConfig(questionnaireConfig => {
            let config = [...questionnaireConfig];
            let el = config.splice(idx, 1);
            config.splice(order, 0, el[0]);
            return config;
        });
    };

    const deleteQuestion = (idx) => {
        questionnaireConfig.splice(idx, 1);
        updateForm(questionnaireConfig[0], 0)
    }

    return (
        <div className='2xl:w-3/6 lg:w-4/6 w-full mx-auto relative'>
                <div className='mt-5 2xl:mt-0 md:w-6/6 w-full mx-auto 2xl:absolute top-0 -left-90 2xl:w-84 h-full 2xl:mb-0 mb-8'>
                    <div className='mx-auto 2xl:mr-8 top-32 sticky h-auto 2xl:w-full w-full md:w-full lg:w-2/4' >
                        <KeywordList validSelect={
                                        lastSelected.current.length > 0 &&
                                        questionnaireConfig[lastSelected.current[0]] &&
                                        questionnaireConfig[lastSelected.current[0]].options[lastSelected.current[1]]}
                                     categoriesUpdatedForQuestion={categoriesUpdatedForQuestion}
                                     selectedArr={selectedArr.current}
                                     questionnaireConfig={questionnaireConfig}
                        />
                    </div>
                </div>
                <div className='w-full relative'>
                    {questionnaireConfig.map((quizConfig, idx) =>
                        (
                            <div key={quizConfig.key + idx} className='mt-8'>
                                <QuestionnaireForm
                                    categoriesSelectedFunc={categoriesSelectedFunc}
                                    quizConfig={quizConfig}
                                    optionsLength={quizConfig.options.length}
                                    editMode={quizConfig.editMode}
                                    alphaMode={true}
                                    idx={idx}
                                    changeOrder={changeOrder}
                                    updateForm={updateForm}
                                    lang={lang}
                                />
                                {questionnaireConfig.length > 1 &&
                                <div className='relative'>
                                    <Button color={'red'} label='sv.questionnaire_form.delete' onClick={() => deleteQuestion(idx)}
                                            className='top-0 left-0 absolute'/>
                                </div>
                                }
                            </div>)
                    )}
                    <div className='flex justify-center'>
                        <button className='text-green-400 hover:text-green-500 text-9xl' onClick={() => newQuestion()}>+</button>
                    </div>
                </div>
                <div className='mt-5 2xl:mt-0 w-full mx-auto 2xl:absolute top-0 -right-40 2xl:w-40 h-full 2xl:mb-0 mb-8'>
                    <div className='2xl:ml-8 mx-auto top-32 mt-8 sticky h-auto 2xl:w-full w-full md:w-full lg:w-2/4'>
                        <Button color={'green'} onClick={(e) => saveQuestionnaireToDB(e)} className='w-full mb-4' label='sv.questionnaire_form.save' />
                        <LangButtons lang={lang} changeLang={changeLange} />
                    </div>
                </div>
        </div>
    )
}

// >> data function is used to retrieve the quiz config
// >> data function returns a promise with response =>
//   [{
//   question: el.question,
//   multiselect: el.multiselect,
//   lastSelectedIdx: el.lastSelectedIdx,
//   options: [{label: op.label, correct: op.correct, selected: op.correct}, ....]
//   }, .....]
//
// >> save function used to save quiz config to DB
// >> save function returns a promise with response and takes single parameter (quizConfig) which is =>
// => with all the temporary params even though they are useless, could use further clean-up if necessary =>
// => the data format mentioned in 'data() function' is what's actually saved in DB and this body "can be" formatted to match that format
//   [{
//          key: new Date().getTime(),
//          question: '',
//          editMode: true,
//          multiselect: false,
//          lastSelectedIdx: -1,
//          lastSelectedPreEditIdx: -1,
//          options: [
//          {label: '', correct: false, selected: false, preEdit: false}
//      ]
//      }, .....]
QuestionnaireEdit.protoTypes = {
    data: PropTypes.func.isRequired,
    save: PropTypes.func.isRequired,
};

// Keyword list
// Displayed left of questionnaire
export const KeywordList = (props) => {
    const [keywords, setKeywords] = useState([]);

    // When clicking on category box under question
    // It'll select currently selected keywords
    useEffect(() => {
        if (!props.selectedArr || props.selectedArr.length === 0 ) {
            setKeywords(keywords => keywords.map((it) => {
                it.selected = false;
                return it;
            }))
            return;
        }
        props.selectedArr.forEach((it) => {
            let kw = keywords.find((it2) => it2.label === it)
            kw.selected = true;
        })
        setKeywords([...keywords])
    }, [props.selectedArr])

    // Getting keywords from DB
    useEffect(() => {
        api.get('/admin/keywords')
            .then(res => {
                setKeywords(res.data.keywords.map((it) => {
                    return {label: it, selected: false}
                }));
            }).catch(e => {
                console.log(e);
        });
    }, [])

    // New keyword form
    const initValues = {keywords: ''};
    const inputFields = [
        {type: 'text', name: 'keywords', placeholder: 'sv.questionnaire_form.keyword', label: 'sv.questionnaire_form.keyword'}
    ];
    const validationSchema = {
        keywords: Yup.string()
            .min(1, <FormattedMessage id="sv.validation.min_1" />)
            .max(255, <FormattedMessage id="sv.validation.max_255" />)
    };
    const handleSubmit = (values, { resetForm, setErrors, setSubmitting }) => {
        const keyword = values.keywords.charAt(0).toUpperCase() + values.keywords.slice(1);
        api.post('admin/keyword', {keyword: keyword}).then(() => {
            setKeywords(() => {
                let temp = [...keywords];
                temp.push({label: keyword, selected: false});
                resetForm();
                setSubmitting(false);
                return temp
            })
        }).catch((e) => {
            setSubmitting(false);
            if(e.response.data.error === 'keyword_already_exists'){
                //TODO Arturs: add translation
                setErrors({keywords: 'Keyword already exists'});
            }
        });
    };

    // Deleting keyword
    // Checking if it's already used before deleting
    // Won't deleted already tied keywords to questions
    // Older questionnaire keywords can be deleted
    const handleDelete = () => {
        const kwords = keywords.filter((it) => it.selected === true);
        kwords.forEach((it) => {
            let invalid = false;
            for (const quiz2 of props.questionnaireConfig) {
                for (const op of quiz2.options) {
                    if (op.categories.includes(it.label)) {
                        invalid = true;
                        //TODO: Arturs: Error msg display
                        break;
                    }
                }
                if (invalid) {
                    break;
                }
            }
            if (!invalid) {
                api.post('admin/keyword/delete', {keyword: it.label}).then(() => {
                    setKeywords(keywords => keywords.filter((it2) => {return it2.label !== it.label}))
                }).catch((e) => {
                    console.log(e.response.data.error);
                    //TODO: Arturs: Error display?
                });
            }
        });
    }

    // Keyword click
    const onSelect = (idx) => {
        let bool = !keywords[idx].selected;
        setKeywords((keywords) => {
            keywords[idx].selected = bool;
            return [...keywords];
        })
    }

    return (
        <div className='p-2'>
            <Form
                initValues={initValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
                fields={inputFields}
                submitBtnLabel="sv.questionnaire_form.add_keyword"
                submitBtnFullWidth
            />
            <div className='mt-8'>
                <div className='mb-2 text-center font-medium'>
                    <FormattedMessage id='sv.questionnaire_form.keyword_list'/>
                </div>
                <div className='border border-gray-200 flex-grow flex flex-wrap pb-2 pr-2 max-h-50vh overflow-hidden overflow-y-scroll'>
                        {keywords.map((keyword, idx) => (
                           <KeywordClickable validSelect={props.validSelect} onClick={onSelect} idx={idx} selected={keyword.selected} keyword={keyword.label} />
                        ))}
                </div>
                    {keywords.some((it) => it.selected === true) &&
                        <>
                            {!props.validSelect &&
                            <div className='flex justify-center mt-2'>
                                <Button onClick={() => handleDelete()} className='2xl:w-full lg:w-full md:w-1/3 w-full' color='red' label='sv.questionnaire_form.delete'/>
                            </div>
                            }
                        </>
                    }
                    {props.validSelect &&
                        <div className='flex justify-center mt-2'>
                            <Button className='2xl:w-full lg:w-full md:w-1/3 w-full' color='green'
                                    onClick={() => props.categoriesUpdatedForQuestion(keywords.filter((it) => it.selected).map((it) => it.label))} label='sv.questionnaire_form.set_categories'/>
                        </div>
                    }
            </div>
        </div>
    )
}

KeywordList.protoTypes = {
    selectedArr: PropTypes.array.isRequired,
    questionnaireConfig: PropTypes.bool.isRequired,
    validSelect: PropTypes.bool.isRequired,
    keyword: PropTypes.string.isRequired
};

// Keyword button
export const KeywordClickable = (props) => {
    return (
        <button onClick={() => props.onClick(props.idx)}
            className={'flex-grow rounded text-left px-4 py-2 border-2 ml-2 mt-2 break-words-fix text-center '
            + (props.selected ? !props.validSelect ? 'border-red-100 bg-red-50 text-red-500' : 'border-green-100 bg-green-50 text-green-500' : 'border-gray-100 bg-gray-50 ')}><span className='break-words'>{props.keyword}</span></button>
    )
}

KeywordClickable.protoTypes = {
    onClick: PropTypes.func.isRequired,
    selected: PropTypes.bool.isRequired,
    validSelect: PropTypes.bool.isRequired,
    keyword: PropTypes.string.isRequired
};

export const LangButtons = (props) => {
    return (
        <div className='flex 2xl:flex-col gap-x-1 gap-y-1'>
            <Button onClick={(e) => { props.changeLang('lv'); e.target.blur()}} disabled={props.lang === 'lv'} color='neutral' className='2xl:w-full lg:w-full md:w-1/3 w-full' staticLabel='LV' />
            <Button onClick={(e) => { props.changeLang('en'); e.target.blur()}} disabled={props.lang === 'en'} color='neutral' className='2xl:w-full lg:w-full md:w-1/3 w-full' staticLabel='EN' />
            <Button onClick={(e) => { props.changeLang('ru'); e.target.blur()}} disabled={props.lang === 'ru'} color='neutral' className='2xl:w-full lg:w-full md:w-1/3 w-full' staticLabel='RU' />
        </div>
    )
}
