import React, { useEffect, useState } from 'react';
import { Popover, Transition } from '@headlessui/react'
import { connect } from 'react-redux';
import { i18nActions, userActions } from '../_actions';
import { api, history } from '../_library';
import { Button, Text, Link, Dropdown, Modal } from './';
import { MenuIcon } from '@heroicons/react/outline';
import { FormattedMessage, useIntl } from 'react-intl';
import logo from '../assets/img/soulvirtue_logo.svg';


const Navbar = props => {

    const intl = useIntl();
    const [isSignedIn, setIsSignedIn] = useState(false);
    const [isPatient, setIsPatient] = useState(false);
    const [isDoctor, setIsDoctor] = useState(false);
    const [isAdmin, setIsAdmin] = useState(false);
    const [synchronized, setSynchronized] = useState(false);
    const [messageToSupport, setMessageToSupport] = useState('');
    const [sendingInProgress, setSendingInProgress] = useState(false);
    const [contactAdminModalOpen, setContactAdminModalOpen] = useState(false);
    const toggleContactAdminModal = () => setContactAdminModalOpen(!contactAdminModalOpen);
    const [contactAdminModalSuccessOpen, setContactAdminModalSuccessOpen] = useState(false);
    const toggleContactAdminSuccessModal = () => setContactAdminModalSuccessOpen(!contactAdminModalSuccessOpen);

    const handleContactSupport = () => {

        setSendingInProgress(true);

        api.post('/admin/contact/support', {text: messageToSupport})
            .then(res => {
                setSendingInProgress(false);
                setMessageToSupport('');
                toggleContactAdminModal();
                setTimeout(toggleContactAdminSuccessModal, 200); //MEMO: michael: we use timeout to bypass dialog from headlessui errors related to focus and to maximum call stack size exceeded
            }).catch(() => {
                setSendingInProgress(false);
            });
    };

    const renderClients = (onlyClients = false) => {
        if (!isSignedIn || !props.user?.clients?.length) {
            return null;
        }

        if (onlyClients && (!props.user?.clients?.length || props.user?.clients?.length < 2)) {
            return null;
        }

        const currentClient = props.user.clients.find(c => c._id._id === props.user.currentClient._id);

        if (currentClient === undefined) {
            return null;
        }

        let dropdownMenuItems = [];

        if (isPatient && !onlyClients) {
            dropdownMenuItems = [
                {label: 'sv.navbar.profile', onClick: () => history.push('/patient/profile'), borderTop: true},
                {label: 'sv.navbar.contact_support', onClick: toggleContactAdminModal}
            ];
        }

        if (!onlyClients) {
            dropdownMenuItems.push(
                {label: 'sv.navbar.btn.sign_out', onClick: props.logout, borderTop: !dropdownMenuItems.length}
            );
        }

        return (
            // <div className="flex flex-row-reverse">
                <Dropdown
                    className="ml-1 md:ml-5"
                    items={
                        [
                            ...props.user.clients.filter(c => c._id._id !== props.user.currentClient._id)
                                .map(c => (
                                    {label: (c._id.name + ' ' + c._id.surname), onClick: () => props.switchClient(c._id._id)}
                                )),
                            ...dropdownMenuItems
                        ]
                    }
                >
                    <span>
                        {currentClient._id.name}&nbsp;{currentClient._id.surname}
                    </span>
                </Dropdown>
            // </div>
        );
    };

    const renderLangs = () => (
        <Dropdown
            className="ml-1 mr-1"
            items={[
                {label: 'sv.navbar.lang.en', onClick: () => props.changeLanguage('en')},
                {label: 'sv.navbar.lang.lv', onClick: () => props.changeLanguage('lv')},
                {label: 'sv.navbar.lang.ru', onClick: () => props.changeLanguage('ru')}
            ]}
        >
            <span className="uppercase">{props.lang}</span>
        </Dropdown>
    );

    useEffect(() => {

        if (props.user.synchronized) {
            if (!props.user.loggedIn) {
                setIsSignedIn(false);
                setIsPatient(false);
                setIsDoctor(false);
                setIsAdmin(false);
            } else {
                setIsSignedIn(true);
                setIsPatient(false);
                setIsDoctor(false);
                setIsAdmin(false);

                if (props.user.role === 'patient') {
                    setIsPatient(true);
                } else if (props.user.role === 'admin') {
                    setIsAdmin(true);
                } else {
                    setIsDoctor(true);
                }
            }

            setSynchronized(true);
        }
    }, [props.user]);

    if (!synchronized) {
        return null;
    }

    return(
        <>
        <Popover className="fixed w-full bg-white z-30">
            <div className="mx-auto container">
                <div className="flex justify-between items-center border-b-2 border-gray-100 py-2 md:justify-start md:space-x-10">
                    <div className="flex justify-start lg:w-0 lg:flex-1">
                        <div className="bg-rose-300 ml-2 md:ml-0" onClick={() => {!isSignedIn ? history.push('/') : (isAdmin ? history.push('/admin/patients') : (isDoctor ? history.push('/doctor/dashboard') : history.push('/patient/dashboard')))}}>
                            <img src={logo} className="w-auto h-20"  alt="logo" />
                        </div>
                    </div>
                    <div className="md:hidden">
                        {renderClients(true)}
                    </div>
                    <div className="xl:hidden">
                        {renderLangs()}
                    </div>
                    <div className="mr-2 -my-2 xl:hidden">
                        <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                            <span className="sr-only">Open menu</span>
                            <MenuIcon className="h-6 w-6" aria-hidden="true" />
                        </Popover.Button>
                    </div>
                    <Popover.Group as="div" className="hidden xl:flex space-x-10">
                        {!isSignedIn &&
                            <Link to="/apply" className="text-base font-medium text-gray-500 hover:text-gray-900" label="sv.navbar.apply" />
                        }
                        {!isSignedIn &&
                            <Link to="/pricing" className="text-base font-medium text-gray-500 hover:text-gray-900" label="sv.navbar.pricing" />
                        }
                        {(isPatient && isSignedIn) &&
                            <Link to="/patient/dashboard" className="text-base font-medium text-gray-500 hover:text-gray-900" label="sv.navbar.apply" />
                        }
                        {(isPatient && isSignedIn) &&
                            <Link to="/patient/profile/plans" className="text-base font-medium text-gray-500 hover:text-gray-900" label="sv.navbar.pricing" />
                        }
                        {isPatient && isSignedIn  &&
                            <Link to="/patient/consultations" className="text-base font-medium text-gray-500 hover:text-gray-900" label="sv.navbar.consultations" />
                        }
                        {!isAdmin &&
                            <Link to="/news" className="text-base font-medium text-gray-500 hover:text-gray-900" label="sv.navbar.news" />
                        }
                        {isDoctor && isSignedIn &&
                            <Link to="/doctor/chat" className="text-base font-medium text-gray-500 hover:text-gray-900" label="sv.navbar.chats" />
                        }
                        {isDoctor && isSignedIn &&
                            <Link to="/doctor/dashboard" className="text-base font-medium text-gray-500 hover:text-gray-900" label="sv.navbar.consultations" />
                        }
                        {isDoctor && isSignedIn &&
                            <Link to="/doctor/patients" className="text-base font-medium text-gray-500 hover:text-gray-900" label="sv.navbar.patients" />
                        }
                        {isDoctor && isSignedIn &&
                            <Link to="/doctor/profile" className="text-base font-medium text-gray-500 hover:text-gray-900" label="sv.navbar.profile" />
                        }
                        {isDoctor && isSignedIn &&
                            <Link to="/doctor/wallet" className="text-base font-medium text-gray-500 hover:text-gray-900" label="sv.navbar.wallet" />
                        }
                        {isAdmin && isSignedIn &&
                            <Dropdown
                                className="ml-1 mr-1"
                                items={[
                                    {label: 'sv.navbar.patients', onClick: () => history.push('/admin/patients')},
                                    {label: 'sv.navbar.doctors', onClick: () => history.push('/admin/doctors')},
                                    {label: 'sv.navbar.call_feedbacks', onClick: () => history.push('/admin/callFeedbacks')},
                                    {label: 'sv.navbar.questionnaires', onClick: () => history.push('/admin/questionnaires')},
                                    {label: 'sv.navbar.users', onClick: () => history.push('/admin/users')},
                                    {label: 'sv.navbar.freeusers', onClick: () => history.push('/admin/freeusers')},
                                    {label: 'sv.navbar.payouts', onClick: () => history.push('/admin/payouts')},
                                    {label: 'sv.navbar.admins', onClick: () => history.push('/admin/admins')},
                                    {label: 'sv.navbar.news', onClick: () => history.push('/admin/news')},
                                    {label: 'sv.navbar.support', onClick: () => history.push('/admin/support')}
                                ]}
                                label="sv.navbar.admin.menu"
                            />
                        }
                    </Popover.Group>
                    {/*<div className="hidden md:block">*/}
                    {/*    {renderClients()}*/}
                    {/*</div>*/}
                    <div className="hidden xl:flex items-center justify-end xl:flex-1 lg:w-0 sm:px-6">
                        {renderLangs()}
                        {renderClients()}
                        {!isSignedIn &&
                            <>
                                <Button className="ml-16" color="neutral" small onClick={() => history.push('/login')} label="sv.navbar.btn.sign_in" />
                                <Button className="ml-3" small onClick={() => history.push('/signup')} label="sv.navbar.btn.sign_up" />
                            </>
                        }
                    </div>
                </div>
            </div>

            <Transition
                as="div"
                enter="duration-200 ease-out"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="duration-100 ease-in"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
                className="space-y-5 py-5 shadow"
            >
                {!isSignedIn &&
                    <div className="text-center">
                        <Link to="/apply" className="text-base font-medium text-gray-500 hover:text-gray-900" label="sv.navbar.apply" />
                    </div>
                }
                {!isSignedIn &&
                    <div className="text-center">
                        <Link to="/pricing" className="text-base font-medium text-gray-500 hover:text-gray-900" label="sv.navbar.pricing" />
                    </div>
                }
                {isPatient && isSignedIn &&
                    <div className="text-center">
                        <Link to="/patient/dashboard" className="text-base font-medium text-gray-500 hover:text-gray-900" label="sv.navbar.apply" />
                    </div>
                }
                {(isPatient && isSignedIn) &&
                    <div className="text-center">
                        <Link to="/patient/profile/plans" className="text-base font-medium text-gray-500 hover:text-gray-900" label="sv.navbar.pricing" />
                    </div>
                }
                {isPatient && isSignedIn  &&
                    <div className="text-center">
                        <Link to="/patient/consultations" className="text-base font-medium text-gray-500 hover:text-gray-900" label="sv.navbar.consultations" />
                    </div>
                }
                {!isAdmin &&
                    <div className="text-center">
                        <Link to="/news" className="text-base font-medium text-gray-500 hover:text-gray-900" label="sv.navbar.news"/>
                    </div>
                }
                {isPatient && isSignedIn  &&
                    <div className="text-center">
                        <Link to="/patient/profile" className="text-base font-medium text-gray-500 hover:text-gray-900" label="sv.navbar.profile" />
                    </div>
                }
                {isPatient && isSignedIn &&
                    <div className="text-center">
                        <Link onClick={toggleContactAdminModal} className="text-base font-medium text-gray-500 hover:text-gray-900" label="sv.navbar.contact_support" />
                    </div>
                }
                {isDoctor && isSignedIn &&
                    <div className="text-center">
                        <Link to="/doctor/chat" className="text-base font-medium text-gray-500 hover:text-gray-900" label="sv.navbar.chats" />
                    </div>
                }
                {isDoctor && isSignedIn &&
                    <div className="text-center">
                        <Link to="/doctor/dashboard" className="text-base font-medium text-gray-500 hover:text-gray-900" label="sv.navbar.consultations" />
                    </div>
                }
                {isDoctor && isSignedIn &&
                    <div className="text-center">
                        <Link to="/doctor/patients" className="text-base font-medium text-gray-500 hover:text-gray-900" label="sv.navbar.patients" />
                    </div>
                }
                {isDoctor && isSignedIn &&
                    <div className="text-center">
                        <Link to="/doctor/profile" className="text-base font-medium text-gray-500 hover:text-gray-900" label="sv.navbar.profile" />
                    </div>
                }
                {isAdmin && isSignedIn &&
                    <div className="text-center">
                        <Link to="/admin/patients" className="text-base font-medium text-gray-500 hover:text-gray-900" label="sv.navbar.patients" />
                    </div>
                }
                {isAdmin && isSignedIn &&
                    <div className="text-center">
                        <Link to="/admin/doctors" className="text-base font-medium text-gray-500 hover:text-gray-900" label="sv.navbar.doctors" />
                    </div>
                }
                {isAdmin && isSignedIn &&
                    <div className="text-center">
                        <Link to="/admin/callFeedbacks" className="text-base font-medium text-gray-500 hover:text-gray-900" label="sv.navbar.call_feedbacks" />
                    </div>
                }
                {isAdmin && isSignedIn &&
                    <div className="text-center">
                        <Link to="/admin/questionnaires" className="text-base font-medium text-gray-500 hover:text-gray-900" label="sv.navbar.questionnaires" />
                    </div>
                }
                {isAdmin && isSignedIn &&
                    <div className="text-center">
                        <Link to="/admin/users" className="text-base font-medium text-gray-500 hover:text-gray-900" label="sv.navbar.users" />
                    </div>
                }
                {isAdmin && isSignedIn &&
                    <div className="text-center">
                        <Link to="/admin/freeusers" className="text-base font-medium text-gray-500 hover:text-gray-900" label="sv.navbar.freeusers" />
                    </div>
                }
                {isAdmin && isSignedIn &&
                    <div className="text-center">
                        <Link to="/admin/payouts" className="text-base font-medium text-gray-500 hover:text-gray-900" label="sv.navbar.payouts" />
                    </div>
                }
                {isAdmin && isSignedIn &&
                    <div className="text-center">
                        <Link to="/admin/admins" className="text-base font-medium text-gray-500 hover:text-gray-900" label="sv.navbar.admins" />
                    </div>
                }
                {isAdmin && isSignedIn &&
                    <div className="text-center">
                        <Link to="/admin/news" className="text-base font-medium text-gray-500 hover:text-gray-900" label="sv.navbar.news" />
                    </div>
                }
                {isAdmin && isSignedIn &&
                    <div className="text-center">
                        <Link to="/admin/support" className="text-base font-medium text-gray-500 hover:text-gray-900" label="sv.navbar.support" />
                    </div>
                }
                <hr className="h-px ml-5 mr-5" />
                {!isSignedIn &&
                    <div className="flex justify-center">
                        <Button color="neutral" small onClick={() => history.push('/login')} label="sv.navbar.btn.sign_in" />
                        <Button className="ml-3" small onClick={() => history.push('/signup')} label="sv.navbar.btn.sign_up" />
                    </div>
                }
                {isSignedIn &&
                    <div className="text-center cursor-pointer whitespace-nowrap text-base font-medium text-gray-500 hover:text-gray-900" onClick={props.logout}>
                        <FormattedMessage id="sv.navbar.btn.sign_out" />
                    </div>
                }
            </Transition>
        </Popover>
        <Modal isOpen={contactAdminModalOpen} toggle={toggleContactAdminModal}
               title="sv.navbar.modal.contact_support"
               content={
                   <div>
                       <textarea className="w-full" placeholder={intl.formatMessage({id: 'sv.patient.dashboard.modal.placeholder.enter_text'})}
                        value={messageToSupport} onChange={e => setMessageToSupport(e.target.value)}
                       />
                       <div className="flex justify-between mt-10">
                           <Button disabled={sendingInProgress} small label="sv.navbar.modal.contact_support.btn.send" className="mt-2 md:mt-3"
                                   onClick={handleContactSupport} />
                           <Button disabled={sendingInProgress} secondary small label="sv.btn.cancel" className="mt-2 md:mt-3"
                                   onClick={toggleContactAdminModal} />
                       </div>
                   </div>
               }
        />
        <Modal isOpen={contactAdminModalSuccessOpen} toggle={toggleContactAdminSuccessModal}
               title="sv.navbar.modal.contact_support"
               content={
                   <div>
                       <Text left label="sv.navbar.modal.contact_support_success.description" />
                       <div className="flex justify-between mt-10">
                           <Button secondary small label="sv.btn.close" className="mt-2 md:mt-3"
                                   onClick={toggleContactAdminSuccessModal} />
                       </div>
                   </div>
               }
        />
        <div className="h-24" />
    </>
    );

};

function mapStateToProps(state) {
    const { user, i18n } = state;
    return {
        user,
        lang: i18n.lang
    };
}

function mapDispatchToProps(dispatch) {
    return({
        logout: () => {
            dispatch(userActions.logout())
        },
        changeLanguage: lang => {
            dispatch(i18nActions.changeLanguage(lang))
        },
        switchClient: clientId => {
            dispatch(userActions.switchClient(clientId))
        }
    })
}

const connectedNavbar = connect(mapStateToProps, mapDispatchToProps)(Navbar);
export { connectedNavbar as Navbar };
