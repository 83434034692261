import React, { useEffect, Component } from 'react';
import { Router, Route, Switch } from 'react-router-dom';
import { LoadingWithSuspense, Error, PrivateRoute } from './components';
import { connect } from 'react-redux';
import { userActions } from './_actions/';
import { history } from './_library';
import config from './config';
const PageNotFound = React.lazy(() => import(/* webpackChunkName: "page_not_found" */ './screens/PageNotFound').then(module => ({default: module.PageNotFound})));
const Login = React.lazy(() => import(/* webpackChunkName: "login" */ './screens/Login').then(module => ({default: module.Login})));
const Privacy = React.lazy(() => import(/* webpackChunkName: "privacy" */ './screens/Privacy').then(module => ({default: module.Privacy})));
const Terms = React.lazy(() => import(/* webpackChunkName: "terms" */ './screens/Terms').then(module => ({default: module.Terms})));
const PatientDashboard = React.lazy(() => import(/* webpackChunkName: "patient_dashboard" */ './screens/Patient/PatientDashboard').then(module => ({default: module.PatientDashboard})));
const DoctorDashboard = React.lazy(() => import(/* webpackChunkName: "doctor_dashboard" */ './screens/Doctor/DoctorDashboard').then(module => ({default: module.DoctorDashboard})));
const AdminDashboard = React.lazy(() => import(/* webpackChunkName: "admin_dashboard" */ './screens/Admin/AdminDashboard').then(module => ({default: module.AdminDashboard})));
const Signup = React.lazy(() => import(/* webpackChunkName: "signup" */ './screens/Signup').then(module => ({default: module.Signup})));
const PatientProfile = React.lazy(() => import(/* webpackChunkName: "patient_profile" */ './screens/Patient/PatientProfile').then(module => ({default: module.PatientProfile})));
const DoctorProfile = React.lazy(() => import(/* webpackChunkName: "doctor_profile" */ './screens/Doctor/DoctorProfile').then(module => ({default: module.DoctorProfile})));
const AdminPatients = React.lazy(() => import(/* webpackChunkName: "admin_patients_list" */ './screens/Admin/AdminPatients').then(module => ({default: module.AdminPatients})));
const EmailConfirmation = React.lazy(() => import(/* webpackChunkName: "email_confirmation" */ './screens/EmailConfirmation').then(module => ({default: module.EmailConfirmation})));
const PasswordResetRequest = React.lazy(() => import(/* webpackChunkName: "password_reset_request" */ './screens/PasswordResetRequest').then(module => ({default: module.PasswordResetRequest})));
const ResetPassword = React.lazy(() => import(/* webpackChunkName: "reset_password" */ './screens/ResetPassword').then(module => ({default: module.ResetPassword})));
const Home = React.lazy(() => import(/* webpackChunkName: "home" */ './screens/Home').then(module => ({default: module.Home})));
const AdminQuestionnaire = React.lazy(() => import(/* webpackChunkName: "admin_questionnaire" */ './screens/Admin/AdminQuestionnaire').then(module => ({default: module.AdminQuestionnaire})));
const SignupQuestionnaire = React.lazy(() => import(/* webpackChunkName: "signup_questionnaire" */ './screens/SignupQuestionnaire').then(module => ({default: module.SignupQuestionnaire})));
const AdminQuestionnaires = React.lazy(() => import(/* webpackChunkName: "admin_questionnaires" */ './screens/Admin/AdminQuestionnaires').then(module => ({default: module.AdminQuestionnaires})));
const AdminUsers = React.lazy(() => import(/* webpackChunkName: "admin_users" */ './screens/Admin/AdminUsers').then(module => ({default: module.AdminUsers})));
const AdminNews = React.lazy(() => import(/* webpackChunkName: "admin_news" */ './screens/Admin/AdminNews').then(module => ({default: module.AdminNews})));
const Pricing = React.lazy(() => import(/* webpackChunkName: "pricing" */ './screens/Pricing/Pricing').then(module => ({default: module.Pricing})));
const DoctorWallet = React.lazy(() => import(/* webpackChunkName: "doctor_wallet" */ './screens/Doctor/DoctorWallet').then(module => ({default: module.DoctorWallet})));
const AdminPayouts = React.lazy(() => import(/* webpackChunkName: "admin_payouts" */ './screens/Admin/AdminPayouts').then(module => ({default: module.AdminPayouts})));
const AdminAdmins = React.lazy(() => import(/* webpackChunkName: "admin_admins" */ './screens/Admin/AdminAdmins').then(module => ({default: module.AdminAdmins})));
const CreateAdmin = React.lazy(() => import(/* webpackChunkName: "create_admin" */ './screens/Admin/CreateAdmin').then(module => ({default: module.CreateAdmin})));
const AdminConfirmEmail = React.lazy(() => import(/* webpackChunkName: "admin_confirm_email" */ './screens/Admin/AdminConfirmEmail').then(module => ({default: module.AdminConfirmEmail})));
const News = React.lazy(() => import(/* webpackChunkName: "news" */ './screens/News').then(module => ({default: module.News})));
const NewsPost = React.lazy(() => import(/* webpackChunkName: "news_post" */ './screens/NewsPost').then(module => ({default: module.NewsPost})));
const PatientChat = React.lazy(() => import(/* webpackChunkName: "patient_chat" */ './screens/Patient/PatientChat').then(module => ({default: module.PatientChat})));
const DoctorChat = React.lazy(() => import(/* webpackChunkName: "doctor_chat" */ './screens/Doctor/DoctorChat').then(module => ({default: module.DoctorChat})));
const PatientConsultations = React.lazy(() => import(/* webpackChunkName: "patient_consultations" */ './screens/Patient/PatientConsultations').then(module => ({default: module.PatientConsultations})));
const Consultation = React.lazy(() => import(/* webpackChunkName: "consultation" */ './screens/Consultation').then(module => ({default: module.Consultation})));
const AdminCallFeedbacks = React.lazy(() => import(/* webpackChunkName: "admin_call_feedbacks" */ './screens/Admin/AdminCallFeedbacks').then(module => ({default: module.AdminCallFeedbacks})));
const AdminDoctors = React.lazy(() => import(/* webpackChunkName: "admin_doctors" */ './screens/Admin/AdminDoctors').then(module => ({default: module.AdminDoctors})));
const AdminDoctor = React.lazy(() => import(/* webpackChunkName: "admin_doctor" */ './screens/Admin/AdminDoctor').then(module => ({default: module.AdminDoctor})));
const AdminSupportMessages = React.lazy(() => import(/* webpackChunkName: "admin_support_messages" */ './screens/Admin/AdminSupportMessages').then(module => ({default: module.AdminSupportMessages})));
const DoctorsList = React.lazy(() => import(/* webpackChunkName: "doctors_list" */ './screens/Patient/DoctorsList').then(module => ({default: module.DoctorsList})));
const DoctorPatients = React.lazy(() => import(/* webpackChunkName: "doctor_patient" */ './screens/Doctor/DoctorPatients').then(module => ({default: module.DoctorPatients})));
const Signupfreeplan = React.lazy(() => import(/* webpackChunkName: "signup_free" */ './screens/Signupfreeplan').then(module => ({ default: module.Signupfreeplan })));
const AdminFreeUsers = React.lazy(() => import(/* webpackChunkName: "admin_free_users" */ './screens/Admin/AdminFreeUsers').then(module => ({ default: module.AdminFreeUsers })));
const AdminFreeUsersDetail = React.lazy(() => import(/* webpackChunkName: "admin_free_users_detail" */ './screens/Admin/AdminFreeUsersDetail').then(module => ({ default: module.AdminFreeUsersDetail })));
const SignupDoctor = React.lazy(() => import(/* webpackChunkName: "signup_doctor" */ './screens/SignupDoctor').then(module => ({ default: module.SignupDoctor })));

class ErrorBoundary extends Component {
    constructor(props) {
        super(props);
        this.state = {hasError: false};
    }

    static getDerivedStateFromError(e) {
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
    }

    render() {
        if (this.state.hasError) {
            return <Error mainLabel="sv.error.general" buttonLabel="sv.error.general.link.home" link="/" />
        }

        return <div className="position-relative">{this.props.children}</div>;
    }
}


const App = props => {

    useEffect(() => {
        if (!props.user.synchronized) {
            props.loadSettings();
        }
    });

    return (
        <ErrorBoundary>
            <div className="font-body">
            <Router history={history}>
                <Switch>
                    <Route exact path="/" render={props => LoadingWithSuspense(Home, props)} />
                    <Route exact path="/login/:email?" render={props => LoadingWithSuspense(Login, props)} />
                    <Route exact path="/privacy" render={props => LoadingWithSuspense(Privacy, props)} />
                    <Route exact path="/terms" render={props => LoadingWithSuspense(Terms, props)} />
                    <PrivateRoute exact path="/signup/questionnaire" render={props => LoadingWithSuspense(SignupQuestionnaire, props)}
                                  exactRole={config.userRoles['patient']} />
                    <Route exact path="/signup/:plan?" render={props => LoadingWithSuspense(Signup, props)} />
                    <Route exact path="/signupfreeplan/:plan?" render={props => LoadingWithSuspense(Signupfreeplan, props)} />
                    <Route exact path="/signupdoctor" render={props => LoadingWithSuspense(SignupDoctor, props)} />
                    <Route exact path="/pricing" render={props => LoadingWithSuspense(Pricing, props)} />
                    <Route exact path="/apply" render={props => LoadingWithSuspense(DoctorsList, props)} />
                    <Route exact path="/news" render={props => LoadingWithSuspense(News, props)} />
                    <Route exact path="/news/:postId" render={props => LoadingWithSuspense(NewsPost, props)} />
                    <Route exact path="/email/confirm/:token" render={props => LoadingWithSuspense(EmailConfirmation, props)} />
                    <Route exact path="/password/reset/request" render={props => LoadingWithSuspense(PasswordResetRequest, props)} />
                    <Route exact path="/password/new/:token" render={props => LoadingWithSuspense(ResetPassword, props)} />
                    <Route exact path="/admin/email/confirm/:token" render={props => LoadingWithSuspense(AdminConfirmEmail, props)} />
                    <PrivateRoute exact path="/patient/dashboard" render={props => LoadingWithSuspense(PatientDashboard, props)}
                                  exactRole={config.userRoles['patient']}/>
                    <PrivateRoute exact path="/doctor/dashboard" render={props => LoadingWithSuspense(DoctorDashboard, props)}
                                  exactRole={config.userRoles['doctor']}/>
                    <PrivateRoute exact path="/admin/dashboard" render={props => LoadingWithSuspense(AdminDashboard, props)}
                                  exactRole={config.userRoles['admin']}/>
                    <PrivateRoute exact path="/patient/profile/:tab?" render={props => LoadingWithSuspense(PatientProfile, props)}
                                  minimalRole={config.userRoles['patient']}/>
                    <PrivateRoute exact path="/doctor/patients" render={props => LoadingWithSuspense(DoctorPatients, props)}
                                  minimalRole={config.userRoles['doctor']}/>
                    <PrivateRoute exact path="/doctor/profile" render={props => LoadingWithSuspense(DoctorProfile, props)}
                                  minimalRole={config.userRoles['doctor']}/>
                    <PrivateRoute exact path="/doctor/wallet" render={props => LoadingWithSuspense(DoctorWallet, props)}
                                  minimalRole={config.userRoles['doctor']}/>
                    <PrivateRoute exact path="/patient/chat/:doctorId?" render={props => LoadingWithSuspense(PatientChat, props)}
                                  exactRole={config.userRoles['patient']}/>
                    <PrivateRoute exact path="/patient/consultations" render={props => LoadingWithSuspense(PatientConsultations, props)}
                                  exactRole={config.userRoles['patient']}/>
                    <PrivateRoute exact path="/patient/consultations/:consultationId" render={props => LoadingWithSuspense(Consultation, props)}
                                  exactRole={config.userRoles['patient']}/>
                    <PrivateRoute exact path="/consultations/:consultationId" render={props => LoadingWithSuspense(Consultation, props)}
                                  minimalRole={config.userRoles['patient']}/>
                    <PrivateRoute exact path="/doctor/chat" render={props => LoadingWithSuspense(DoctorChat, props)}
                                  exactRole={config.userRoles['doctor']}/>
                    <PrivateRoute exact path="/admin/patients" render={props => LoadingWithSuspense(AdminPatients, props)}
                                  exactRole={config.userRoles['admin']} />
                    <PrivateRoute exact path="/admin/doctors" render={props => LoadingWithSuspense(AdminDoctors, props)}
                                  exactRole={config.userRoles['admin']} />
                    <PrivateRoute exact path="/admin/doctors/:doctorId" render={props => LoadingWithSuspense(AdminDoctor, props)}
                                  exactRole={config.userRoles['admin']} />
                    <PrivateRoute exact path="/admin/callFeedbacks" render={props => LoadingWithSuspense(AdminCallFeedbacks, props)}
                                  exactRole={config.userRoles['admin']} />
                    <PrivateRoute exact path="/admin/questionnaire" render={props => LoadingWithSuspense(AdminQuestionnaire, props)}
                                  exactRole={config.userRoles['admin']} />
                    <PrivateRoute exact path="/admin/questionnaires" render={props => LoadingWithSuspense(AdminQuestionnaires, props)}
                                  exactRole={config.userRoles['admin']} />
                    <PrivateRoute exact path="/admin/users" render={props => LoadingWithSuspense(AdminUsers, props)}
                                  exactRole={config.userRoles['admin']} />
                    <PrivateRoute exact path="/admin/payouts" render={props => LoadingWithSuspense(AdminPayouts, props)}
                                  exactRole={config.userRoles['admin']} />
                    <PrivateRoute exact path="/admin/admins" render={props => LoadingWithSuspense(AdminAdmins, props)}
                                  exactRole={config.userRoles['admin']} />
                    <PrivateRoute exact path="/admin/add" render={props => LoadingWithSuspense(CreateAdmin, props)}
                                  exactRole={config.userRoles['admin']} />
                    <PrivateRoute exact path="/admin/email/confirm/:token" render={props => LoadingWithSuspense(AdminConfirmEmail, props)}
                                  exactRole={config.userRoles['admin']} />
                    <PrivateRoute exact path="/admin/news" render={props => LoadingWithSuspense(AdminNews, props)}
                                  exactRole={config.userRoles['admin']} />
                    <PrivateRoute exact path="/admin/support" render={props => LoadingWithSuspense(AdminSupportMessages, props)}
                                  exactRole={config.userRoles['admin']} />
                    <PrivateRoute exact path="/admin/freeusers" render={props => LoadingWithSuspense(AdminFreeUsers, props)}
                                  exactRole={config.userRoles['admin']} />
                    <PrivateRoute exact path="/admin/freeusersdetail" render={props => LoadingWithSuspense(AdminFreeUsersDetail, props)}
                                  exactRole={config.userRoles['admin']} />

                    <Route exact path="/404" render={props => LoadingWithSuspense(PageNotFound, props)} />
                    <Route render={props => LoadingWithSuspense(PageNotFound, props)} />
                </Switch>
            </Router>
            </div>
        </ErrorBoundary>
    );
};

function mapStateToProps(state) {
    const { user } = state;
    return {
        user
    };
}

function mapDispatchToProps(dispatch) {
    return({
        loadSettings: () => {
            dispatch(userActions.loadSettings())
        }
    })
}

const connectedApp = connect(mapStateToProps, mapDispatchToProps)(App);
export { connectedApp as App };
