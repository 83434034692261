import React, { useEffect, useRef, useState } from 'react';
import TextareaAutosize from 'react-textarea-autosize';
import { Text } from './Text';
import PropTypes from 'prop-types';
import * as react from 'react';
import { FormattedMessage } from "react-intl";


export const QuestionnaireInput = react.memo(({onOptionSelect, ...props}) => {

    // Converting '1stRow\n2ndRow' to 2 divs where it breaks on \n
    const convertTxtAreaToDivs = (value) => {
        if(value === '' || !value){
            return ''
        }
        let jsxArr = [];
        let arrayOfText = value.split(/\n/g);
        arrayOfText.forEach( (it, idx) => {
            jsxArr.push(<div key={idx} className='break-all text-center block w-full'>{it}</div>);
        })
        return jsxArr;
    }

    const [value, setValue] = useState(convertTxtAreaToDivs(props.label) || '');
    const [selected, setSelected] = useState(props.selected)

    useEffect(() => {
        setSelected(props.selected);
    }, [props.selected])

    const saveEvent = (label, idx = -1) => {
        props.updateOptionLabel(props.isQuestion, label, idx)
        setValue(convertTxtAreaToDivs(label));
    }

    const onOptionClick = () => {
        if (onOptionSelect) {
            onOptionSelect(props.idx);
            setSelected(selected => !selected);
        }
    }

    return (
        <EditableInput {...props} selected={selected} saveEvent={saveEvent}>
            <button onClick={() => onOptionClick()} disabled={props.isQuestion} className={'w-full h-full flex items-center justify-center ' + (props.isQuestion ? '': 'bg-blue-200 ') +
            (selected ? 'transition duration-300 ease-out-in bg-blue-200 bg-blue-400 ' : '' ) + (props.isQuestion ? 'pointer-events-none ' : '') + (props.editMode ? 'border border-black border-dashed' : '')}>
                <div className='p-4 min-h-28 flex items-center flex-col justify-center'>
                    {value}
                </div>
            </button>
        </EditableInput>
    );
});

QuestionnaireInput.protoTypes = {
    onOptionSelect: PropTypes.func.isRequired,
    idx: PropTypes.number.isRequired,
    selected: PropTypes.bool.isRequired,
    updateOptionLabel: PropTypes.func,
    editMode: PropTypes.bool,
    deleteBtn: PropTypes.bool,
    deleteOption: PropTypes.func,
    options: PropTypes.shape({
        label: PropTypes.string,
        correct: PropTypes.bool.isRequired,
        selected: PropTypes.bool.isRequired,
        preEdit: PropTypes.bool,
    }).isRequired
};

export const KeywordList = (props) => {
    const [prevCategories, setPrevCategories] = useState([]);

    useEffect(() => {
        if (prevCategories && !prevCategories.length > 0 && props.categories) {
            setPrevCategories(props.categories.filter((it) => {
                return !(prevCategories.includes(it))
            }));
        } else {
            setPrevCategories(props.categories)
        }
    }, [props.categories])
    return(
        <div className='flex flex-wrap w-100'>
            {prevCategories && prevCategories.map((it, idx) => {
                return (
                    <KeywordClickableMini idx={idx} keyword={it} />
                )}
            )}
        </div>
    )
}

export const KeywordClickableMini = (props) => {
    return (
        <span
                className={'flex-grow rounded text-left text-sm py-1 px-2 ml-2 mt-2 break-words-fix text-center border '
                + (props.selected ? 'border-red-100 bg-red-50 text-red-500' : 'border-gray-300 bg-white ')}><span className='break-words'>{props.keyword}</span></span>
    )
}


export const EditableInput = ({saveEvent, ...props}) => {

    const [displayed, setDisplayed] = useState(false);

    const labelRef = useRef(props.label);
    const inputRef = useRef({});

    useEffect(() => {
        inputRef.current.value = props.label;
    }, [])

    const saveClick = () => {
        saveEvent(inputRef.current.value, props.idx);
        setDisplayed(displayed => !displayed);
    }

    const editClick = () => {
        setDisplayed(displayed => !displayed);
        inputRef.current.value = labelRef.current;
    }

    return (
        <div className='w-full h-full'>
            <div className='flex items-center h-full w-full'>
                <div className='w-full h-full relative'>
                    {!props.isQuestion && props.editMode === true && props.selected &&
                        <div className='w-3/5 bg-green-500 text-white absolute h-5 -top-5 text-sm rounded-tr-md rounded-tl-md'><FormattedMessage id='sv.admin_questionnaires.table.answer_correct' /></div>
                    }

                    {props.children}

                    <div className={'absolute top-0 flex items-center w-full h-28 bg-white px-5 ' + (displayed ? '' : 'hidden')}>
                        <TextareaAutosize ref={inputRef} className={'text-center focus:ring-transparent border-l-0 border-t-0 border-r-0 border-b-2 outline-none focus:outline-none max-h-full w-full'} />
                    </div>

                    {props.editMode === true &&
                        <div className='absolute -right-12 top-0 w-12 h-full'>
                            <SettingBtns saveClick={saveClick} editClick={editClick} displayed={displayed} {...props} txtRef={inputRef.current} setDisplayed={setDisplayed}/>
                        </div>
                    }
                    {!props.isQuestion && props.editMode &&
                    <div  className='w-full'>
                        <button className={'block pb-2 w-full min-h-8 pr-2 rounded-br-md rounded-bl-md ' + (props.categoriesSelected ? 'border-green-300 border-t-2 border-b-2 border-l-2 border-r-2 ' : 'border-b border-l border-r border-gray-400 ')}    onClick={() => props.categoriesSelectedFunc(props.idx, props.qIdx)}>
                            <KeywordList mini={true} categories={props.categories}/>
                        </button>
                    </div>
                    }
                </div>
            </div>
        </div>
    );
}

EditableInput.protoTypes = {
    isQuestion: PropTypes.bool,
    editMode: PropTypes.bool,
    selected: PropTypes.bool.isRequired
};

export const SettingBtns = (props) => {
    return (
        <div className='table table-auto w-20 text-base'>
                {props.displayed ?
                    (<SettingBtn className='rounded-tr-lg bg-gray-300'>
                        <button onClick={() => props.editClick()} className='block'><Text label='sv.questionnaire_form.cancel'/></button>
                    </SettingBtn>)
                        :
                    (<SettingBtn className={'rounded-tr-lg bg-yellow-400 ' + (props.deleteBtn ? '' : 'rounded-br-lg')}>
                        <button onClick={() => props.editClick()} className='block'><Text label='sv.questionnaire_form.edit'/></button>
                    </SettingBtn>)
                }

                {props.displayed &&
                    (<SettingBtn className={((props.deleteBtn === true) ? '' : 'rounded-br-lg ') + 'bg-green-400'}>
                        <button onClick={() => {props.saveClick()}} className='block'><Text label='sv.questionnaire_form.save'/></button>
                    </SettingBtn>)
                }

                {props.deleteBtn === true &&
                    (<SettingBtn className='rounded-br-lg bg-red-500'>
                        <button className='block' onClick={() => props.deleteOption(props.idx)}><Text label='sv.questionnaire_form.delete'/></button>
                    </SettingBtn>)
                }
        </div>
    );
}

SettingBtns.protoTypes = {
    editClick: PropTypes.func.isRequired,
    saveClick: PropTypes.func.isRequired,
    deleteOption: PropTypes.func.isRequired
};

// Just wrapper around editMode buttons
export const SettingBtn = (props) => (
    React.cloneElement(props.children, {
            className: 'w-full h-6 text-white ' + (props.className ? props.className : '')
        })
)
