import React, { useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import { Text } from './';
import PropTypes from 'prop-types';
import { formatDate, formatNumber } from '../_library/common';


export const Table = props => {
    return(
        <div className="flex flex-col bg-white">
            <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                    <div className="overflow-hidden border-gray-200">
                        <table className="w-full table-fixed">
                            <thead className="bg-gray-100">
                            <tr>
                                {props.columns.map((column, idx) => (
                                        <th key={idx} scope="col"
                                            className={'px-6 py-3 text-left text-sm uppercase tracking-wider ' + (column.width ? column.width : '' )}>
                                            <FormattedMessage id={column.label} />
                                        </th>
                                    )
                                )}
                                {props.actions &&
                                    <th scope="col" className={'px-6 py-3 text-left text-sm uppercase tracking-wider'}>
                                        <FormattedMessage id={'Actions'}/>
                                    </th>
                                }
                            </tr>
                            </thead>
                            <tbody className="bg-white">
                                {props.rows.map((row, idx) => (
                                    <tr key={idx}>
                                        {props.columns.map((column, columnIdx) => {
                                                switch (column.type) {
                                                    case 'rows':
                                                        return (
                                                            <td key={idx + '_' + columnIdx} className="px-6 py-3">
                                                                <div>
                                                                    {row[column.name].map((columnRow, columnRowIdx) => (
                                                                        <div key={idx + '_' + columnRowIdx}
                                                                             className={'text-sm flex break-words ' + (columnRow.gray ? 'text-gray-500' : 'font-medium text-gray-900')}>
                                                                            {column.includeIndex && <>{(columnRowIdx + 1)}.&nbsp;</>}
                                                                            {column.rows.map((columnRowName, columnRowNameIdx) => (
                                                                                    <div key={idx + '_' + columnRowIdx + '_' + columnRowNameIdx}>
                                                                                        {columnRow[columnRowName]}
                                                                                        {column.actions &&
                                                                                            <>{column.actions.map((action, idx) => {
                                                                                                    if (action.hideWhen && columnRow[action.hideWhen] === action.hideWhenValue) {
                                                                                                        return null;
                                                                                                    }

                                                                                                    return (
                                                                                                        <a key={idx} className="cursor-pointer font-semibold text-indigo-600 hover:text-indigo-500 hover:underline m-1"
                                                                                                           onClick={() => action.onClick(row, columnRow)}>
                                                                                                            <FormattedMessage id={action.label}/>
                                                                                                        </a>
                                                                                                    );
                                                                                                }
                                                                                            )}
                                                                                            </>
                                                                                        }
                                                                                    </div>
                                                                                )
                                                                            )}
                                                                        </div>
                                                                        )
                                                                    )}
                                                                </div>
                                                            </td>
                                                        );
                                                    case 'status':
                                                        return (
                                                            <td key={idx + '_' + columnIdx} className={"px-6 py-3 whitespace-nowrap " + ((idx % 2 === 1) ? 'bg-gray-50' : '')}>
                                                                <span className="px-2 inline-flex text-xs items-center font-semibold rounded-full bg-gray-100">
                                                                    {column.icons &&
                                                                        <span className="pr-2">{column.icons[row[column.name]]}</span>
                                                                    }
                                                                    <span>
                                                                        <FormattedMessage id={props.statuses[row[column.name]]} />
                                                                    </span>
                                                                </span>
                                                            </td>
                                                        );
                                                    case 'role':
                                                        return (
                                                            <td key={idx + '_' + columnIdx} className="px-6 py-3 whitespace-nowrap text-sm text-gray-500">
                                                                <FormattedMessage id={props.roles[row[column.name]]} />
                                                            </td>
                                                        );
                                                    case 'date':
                                                        return (
                                                            <td key={idx + '_' + columnIdx} className={"px-6 py-3 whitespace-nowrap " + ((idx % 2 === 1) ? 'bg-gray-50' : '')}>
                                                                <div className="text-sm font-medium text-gray-900">
                                                                    {formatDate(row[column.name], true)}
                                                                </div>
                                                            </td>
                                                        );
                                                    case 'number':
                                                        return (
                                                            <td key={idx + '_' + columnIdx} className={"px-6 py-3 whitespace-nowrap " + ((idx % 2 === 1) ? 'bg-gray-50' : '')}>
                                                                <div className="text-sm font-medium text-gray-900">
                                                                    {formatNumber(row[column.name], true)}
                                                                </div>
                                                            </td>
                                                        );
                                                    default:
                                                        return (
                                                            <td key={idx + '_' + columnIdx} className={"px-6 py-3 whitespace-nowrap text-gray-900 overflow-hidden " + ((idx % 2 === 1) ? 'bg-gray-50' : '')}>
                                                                {column.name.indexOf('.') !== -1 ? column.name.split('.').reduce((prev, curr) => (prev ? prev[curr] : null), row) : row[column.name]}
                                                            </td>
                                                        );
                                                }
                                            }
                                        )}
                                        {props.actions &&
                                            <td className={"px-6 py-3 whitespace-nowrap text-gray-900 overflow-hidden " + ((idx % 2 === 1) ? 'bg-gray-50' : '')}>
                                                {props.actions.map((action, idx) => {

                                                        if (action.hideWhen && row[action.hideWhen] === action.hideWhenValue) {
                                                            return null;
                                                        }

                                                        return (
                                                            <a key={idx} className="cursor-pointer font-semibold text-indigo-600 hover:text-indigo-500 hover:underline m-1"
                                                                onClick={() => action.onClick(row)}>
                                                                <FormattedMessage id={action.label}/>
                                                            </a>
                                                        );
                                                    })
                                                }
                                            </td>
                                        }
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            {props.pagination &&
                <div className="py-3 pr-4 pl-6">
                    <span className="text-gray-500 text-sm">{props.total} <Text label="sv.table.total"/></span>
                    <TableNavigation setPage={props.setPage} total={props.total} page={props.page} limit={props.limit}
                                     className="float-right"/>
                </div>
            }
        </div>
    );
};

Table.protoTypes = {
    columns: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string,
        label: PropTypes.string
    })).isRequired,
    rows: PropTypes.arrayOf(PropTypes.object).isRequired,
    statuses: PropTypes.object,
    roles: PropTypes.object,
    actions: PropTypes.arrayOf(PropTypes.shape({
        onClick: PropTypes.func.isRequired,
        label: PropTypes.string.isRequired
    })),
    pagination: PropTypes.bool,
    setPage: PropTypes.func
};


export const TableNavigation = ({total, page, limit, ...props}) => {
    let firstRender = useRef(true);
    const lastPage = Math.ceil(total/limit);


    const numberBtns = () => {
        let rows = [];
        let firstFrozen = false;
        let lastFrozen = false;
        let mathCeil = lastPage-3;
            if(page < 3){
                firstFrozen = true;
            } else if (page > mathCeil) {
                lastFrozen = true;
            }
        for (let i = 1; i <= lastPage && i <= 5; i++) {
            let label = !firstFrozen ? !lastFrozen ? page + i - 2 : i + lastPage - 5 : i;
            rows.push(
                <NavButton key={i} page={label-1} className={(page+1 === label) ? 'bg-gray-200' : 0} label={label} setPage={props.setPage}/>
            );
        }
        firstRender.current = false;
        return rows
    }

    if (lastPage < 2) {
        return null;
    }

    return (
        <div className={"flex flex-row space-x-1 " + (props.className ? props.className : '')}>
            <NavButton label="<<" page={0} className="w-9" disabled={page === 0} setPage={props.setPage}/>
            <NavButton label="<" page={page - 1} className="w-9" disabled={page === 0} setPage={props.setPage}/>
            {numberBtns()}
            <NavButton label=">" page={page + 1} className="w-9" disabled={page === lastPage-1} setPage={props.setPage}/>
            <NavButton label=">>" page={lastPage-1} className="w-9" disabled={page === lastPage-1} setPage={props.setPage}/>
        </div>
    );
};

TableNavigation.protoTypes = {
    setPage: PropTypes.func.isRequired //setPage function which receives page number - setPage(page: Integer)
};

export const NavButton = (props) => (
    <button onClick={() => props.setPage(props.page)} disabled={props.disabled}
            className={"rounded px-2 disabled:cursor-not-allowed text-gray-400 " +
            (!props.disabled ? 'hover:bg-gray-200 hover:text-black ' : '') +
            (props.className ? props.className + ' ' : '')}>
        {props.label}
    </button>
);

NavButton.protoTypes = {
    label: PropTypes.string.isRequired,
    setPage: PropTypes.func.isRequired, //setPage function which receives page number - setPage(page: Integer)
    disabled: PropTypes.bool,
    className: PropTypes.string
};
