import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link as RRDLink } from 'react-router-dom';


export const Link = props => {

    let children = props.children;

    if (props.label) {
        children = <FormattedMessage id={ props.label } />;
    }

    const parseExternalLink = link => {
        if (link && !link.includes('http') && !link.includes('https')) {
            link = 'http://' + link;
        }

        return link;
    };

    if (props.external) {
        return <a href={parseExternalLink(props.to)} className={'font-medium hover:underline text-sv-p hover:text-sv-p-l ' + (props.className || '')}
                  {...(props.targetBlank ? {target: '_blank'} : undefined)}>
            {children}
        </a>;
    }

    if (props.onClick !== undefined) {
        return <span onClick={props.onClick} className={'font-medium hover:underline text-sv-p hover:text-sv-p-l cursor-pointer ' + (props.className || '')}>
            {children}
        </span>;
    }

    return <RRDLink to={props.to} className={'font-medium hover:underline text-sv-p hover:text-sv-p-l ' + (props.className || '')}>
        {children}
    </RRDLink>;

};
