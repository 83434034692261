import React from 'react';
import { Text } from "./";
import PropTypes from 'prop-types';


export const FormError = (props) => (
        <>
            {props.meta.touched && props.meta.error &&
                <Text left small className="text-red-500 sm:text-sm">{props.meta.error}</Text>
            }
        </>
    );

FormError.propTypes = {
    meta: PropTypes.object.isRequired
};
