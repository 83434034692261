import React, { Fragment } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/solid';
import { Text, Link } from './';
import PropTypes from 'prop-types';


export const Dropdown = props => (
    <Menu as="div" className={'relative inline-block text-left z-20  ' + (props.className || '')}>
        <div>
            <Menu.Button className="inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-2 md:px-4 py-1 md:py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500">
                {props.label &&
                    <Text label={props.label} />
                }
                {props.children &&
                    <>{props.children}</>
                }
                <ChevronDownIcon className="-mr-1 ml-1 md:ml-2 h-5 w-5" aria-hidden="true" />
            </Menu.Button>
        </div>

        <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
        >
            <Menu.Items className="origin-top-right absolute right-0 mt-2 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                <div className="py-1">
                    {props.items.map((item, idx) => (
                            <Menu.Item key={idx}>
                                {({ active }) => (
                                    <Link
                                        to={item.to}
                                        onClick={item.onClick}
                                        className={'block px-4 py-2 text-sm ' + (active ? 'bg-gray-100 text-gray-900 ' : 'text-gray-700 ') +
                                            ((item.borderTop && idx > 0) ? 'border-t border-gray-200 mt-4 pt-4' : '')}
                                        label={item.label}
                                    />
                                )}
                            </Menu.Item>
                        )
                    )}
                </div>
            </Menu.Items>
        </Transition>
    </Menu>
);

Dropdown.propTypes = {
    label: PropTypes.string,
    items: PropTypes.arrayOf(PropTypes.shape({
        label: PropTypes.string.isRequired,
        to: PropTypes.string,
        onClick: PropTypes.func
    })).isRequired,
    className: PropTypes.string
};
