import React, { useEffect } from 'react';
import { Formik, Form as FForm, useFormikContext } from 'formik';
import {
    Button,
    FormInput,
    FormSelect,
    FormCheckbox,
    FormInlineDatepicker,
    FormImageInput,
    FormInputList,
    FormEditor,
    FormSelectList,
    FormLabel,
    Text
} from './';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import DateTimePicker from 'react-datetime-picker'


const FormikOnChangeComponent = props => {
    const { values } = useFormikContext();
    useEffect(() => {
        props.onChange(values);
    }, [values]);

    return null;
};

FormikOnChangeComponent.protoTypes = {
    props: PropTypes.object.isRequired
};

export const Form = props => {

    return(
        <Formik
            enableReinitialize={props.enableReinitialize}
            initialValues={props.initValues}
            validationSchema={Yup.object().shape(props.validationSchema)}
            onSubmit={props.onSubmit}
        >
            {({ isSubmitting, dirty, isValid, setFieldValue, values, submitForm }) => (
                <FForm autoComplete="off" className={'space-y-6 ' + (props.className || '')}>
                    {props.onChange &&
                        <FormikOnChangeComponent onChange={props.onChange} />
                    }
                    <div className="space-y-2">
                        {props.fields.map((field, idx) => {
                                switch (field.type) {
                                    case 'checkbox':
                                        return <FormCheckbox key={idx} {...field} />;
                                    case 'select':
                                        return <FormSelect key={idx} {...field} />;
                                    case 'inlineDate':
                                        return <FormInlineDatepicker
                                            key={idx}
                                            {...field}
                                            onChange={setFieldValue}
                                            date={values[field.name]} />;
                                    case 'dateTimePicker':
                                        return <>
                                            {field.label && <FormLabel label={field.label} name={field.name}/>}
                                            <DateTimePicker
                                              name={field.name}
                                              returnValue="start"
                                              onChange={(val) => {field.onChange(val); setFieldValue(field.name, val)}}
                                              value={field.value}
                                              format={field.format ? field.format : ''}
                                              disableClock={true}
                                              required={true}
                                              minDate={field.minDate}
                                              maxDate={field.maxDate}
                                              className="mb-40"
                                            />
                                        </>
                                    case 'image':
                                        return <FormImageInput key={idx} {...field} onChange={setFieldValue} />;
                                    case 'textarea':
                                        return <FormInput key={idx} tag="textarea" {...field} />;
                                    case 'input_list':
                                        return <FormInputList key={idx} {...field} onChange={setFieldValue} />;
                                    case 'select_list':
                                        return <FormSelectList key={idx} {...field} onChange={setFieldValue} />;
                                    case 'editor':
                                        return <FormEditor key={idx} {...field} onChange={setFieldValue}
                                                           initValue={props.initValues[field.name]}
                                        />;
                                    case 'inline_fields':
                                        if (!field.root) {
                                            return null;
                                        }

                                        return (
                                                <div className="w-full">
                                                    <div className={'h-auto ' + (field.labelPosition && field.labelWidth ? field.labelPosition === 'left' ? 'order-1 ' + field.labelWidth : 'order-2 :' + field.labelWidth : '')}>
                                                        <FormLabel label={field.label} name={field.name} labelPosition={field.labelPosition} />
                                                    </div>
                                                    <div className="flex">
                                                        <FormInput className="inline-block" noLabel={true} noError={true} key={idx} {...field} type="text" onChange={setFieldValue} />
                                                        {props.fields.filter(f => f.type === 'inline_fields' && f.relatedTo === field.name)
                                                            .map((f, fIdx) => {
                                                                return (
                                                                    <>
                                                                        {f.slashBefore &&
                                                                            <div className="inline-block ml-2 mt-2">/</div>
                                                                        }
                                                                        <FormInput className="inline-block ml-2" key={fIdx} {...f} noLabel={true} noError={true}
                                                                               type="text" onChange={setFieldValue} />
                                                                    </>
                                                                );
                                                            })
                                                        }
                                                        {(props.errors && props.errors[field.name]) ?
                                                            <Text left small className="text-red-500 sm:text-sm">{props.errors[field.name]}</Text>
                                                            :
                                                            <></>
                                                        }
                                                    </div>
                                                </div>
                                        );
                                    default:
                                        return <div>
                                            <FormInput key={idx} {...field} onChange={setFieldValue} />
                                            {(props.errors && props.errors[field.name]) ?
                                              <Text left small className="text-red-500 sm:text-sm">{props.errors[field.name]}</Text>
                                              :
                                              <></>
                                            }
                                        </div>
                                }
                            }
                        )}
                    </div>
                    {(props.dataBeforeButton ?
                        <>
                            {props.dataBeforeButton}
                        </> : <></>
                    )}
                    <div className={'flex space-x-5 ' + (props.cancelBtn ? 'justify-between ' : '')  + (props.submitBtnClass ? props.submitBtnClass : '')}>
                        {!props.noSubmitBtn ?
                            <Button type={props.noSubmitButton ? "button" : "submit"}
                                    {...(props.noSubmitButton ? {onClick: submitForm} : undefined)}
                                    icon={props.submitBtnIcon} label={props.submitBtnLabel}
                                    small={props.submitBtnSmall}
                                    secondary={props.submitBtnSecondary}
                                    disabled={props.ignoreValidation && isSubmitting ? true : props.ignoreValidation ? false : isSubmitting || !dirty || !isValid}
                                    className={(props.submitBtnFullWidth ? 'w-full' : '')}/>
                            : <></>
                        }
                        {props.cancelBtn &&
                            <Button label={props.cancelBtnLabel} small={props.submitBtnSmall} onClick={props.cancelBtn}
                                    disabled={isSubmitting} secondary />
                        }
                    </div>
                </FForm>
            )}
        </Formik>
    );

};

Form.propTypes = {
    initValues: PropTypes.object.isRequired,
    validationSchema: PropTypes.object.isRequired,
    onSubmit: PropTypes.func.isRequired,
    submitBtnIcon: PropTypes.string,
    submitBtnLabel: PropTypes.string.isRequired,
    submitBtnClass: PropTypes.string,
    enableReinitialize: PropTypes.bool,
    className: PropTypes.string,
    submitBtnFullWidth: PropTypes.bool,
    cancelBtn: PropTypes.func,
    cancelBtnLabel: PropTypes.string,
    onChange: PropTypes.func,
    noSubmitBtn: PropTypes.bool
};
