let config = {
    env: 'prod',
    accessTokenName: 'access_token',
    userRoles: {
        'guest': 0,
        'patient': 1,
        'doctor': 2,
        'admin': 3
    },
    userStatus: {
        'created': 0,
        'confirmed': 1,
        'blocked': 2,
        'removed': 3
    },
    language: 'language',
    paymentPlans: {
        advanced: 'advanced',
        basic: 'basic',
        pay_as_you_go: 'pay_as_you_go'
    },
    publishableApiKey: 'pk_test_51Jc8aVAbYBQ4Jxg56MeunRa8yG2GWhcMXTsqoLUqkWS7EY0tsZ2OLdV5YrdwY3sBAHwp5kiVsSE69F4VaeEqi416009CC1WSD0'
};

if (config.env === 'prod') {
    config['baseUrl'] = 'https://api.soulvirtue.lv/v1';
    config['wsBaseUrl'] = 'wss://api.soulvirtue.lv/v1';
    config['publishableApiKey'] = 'pk_live_51Jc8aVAbYBQ4Jxg5E8BkcJGS7Vex6LTveXWTzp33W7KSkSPtcR5Wl0ioIOG6aejB3CCP9aY4NMJ0tKmik7DCde1D00NkptXwjQ';
} else if (config.env === 'dev') {
    config['baseUrl'] = 'https://api.sv.micron.lv/v1';
    config['wsBaseUrl'] = 'wss://api.sv.micron.lv/v1';
    config['publishableApiKey'] = 'pk_test_51Jc8aVAbYBQ4Jxg56MeunRa8yG2GWhcMXTsqoLUqkWS7EY0tsZ2OLdV5YrdwY3sBAHwp5kiVsSE69F4VaeEqi416009CC1WSD0';
} else {
    config['baseUrl'] = 'http://127.0.0.1:5000/v1';
    config['wsBaseUrl'] = 'ws://127.0.0.1:5000/v1';
    config['publishableApiKey'] = 'pk_test_51Jc8aVAbYBQ4Jxg56MeunRa8yG2GWhcMXTsqoLUqkWS7EY0tsZ2OLdV5YrdwY3sBAHwp5kiVsSE69F4VaeEqi416009CC1WSD0';
}

export default Object.freeze(Object.assign({}, config));
