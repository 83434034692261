import { consultationConstants } from '../_constants';


const initialState = {
    partnerSignal: null,
    callStarted: false,
    callFinished: false,
    price: 0,
    callFeedbackQuestions: []
};

export function consultation(state = Object.assign({}, initialState), action) {
    switch (action.type) {
        case consultationConstants.SET_PARTNER_SIGNAL:
            return Object.assign({}, {...state, partnerSignal: action.data.signal});
        case consultationConstants.SET_CALL_STARTED:
            return Object.assign({}, {...state, callStarted: true, consultationId: action.data.consultationId});
        case consultationConstants.SET_CALL_FINISHED:
            return Object.assign({}, {...state, callFinished: true,
                consultationId: action.data.consultationId, price: action.data.price, callFeedbackQuestions: action.data.callFeedbackQuestions});
        default:
            return state;
    }
}
