import React, { useState, useEffect, useRef } from 'react';
import { Text, FormInput } from './';
import { ErrorMessage } from 'formik';
import PropTypes from 'prop-types';


function useOutsideClickHandler(ref, outsideClick) {
    useEffect(() => {
        function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target)) {
                outsideClick();
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref]);
}

export const InputSelect = props => {

    const [opened, setOpened] = useState(false);
    const [selected, setSelected] = useState(null);
    const [selectedName, setSelectedName] = useState('');
    const [selectedList, setSelectedList] = useState([]);
    const [optionName, setOptionName] = useState('name');
    const [optionValue, setOptionValue] = useState('value');
    const [inputValue, setInputValue] = useState('');
    const [options, setOptions] = useState([]);
    const [initialized, setInitialized] = useState(false);

    const wrapperRef = useRef(null);

    useOutsideClickHandler(wrapperRef, () => {
        setOpened(false);

        if (selected) {
            const selectedOption = props.options.find(o => o[optionValue] === selected);
            if (selectedOption !== undefined) {
                setInputValue(selectedOption[optionName]);
            }
        }
    });

    const handleInputValueChange = val => {
        setInputValue(val);
        if (!opened) {
            setOpened(true);
        }
        onChange(val);
    };

    const onChange = props.onChange ? props.onChange : () => {};

    useEffect(() => {
        if (props.selected) {

            if (props.multi) {
                setSelectedList(props.selected);
            } else {
                setSelected(props.selected);
                const name = props.optionName ? props.optionName : optionName;
                const value = props.optionValue ? props.optionValue : optionValue;
                const selectedOption = props.options.find(o => o[value] === props.selected);
                if (selectedOption !== undefined) {
                    setInputValue(selectedOption[name]);
                    setSelectedName(selectedOption[name]);
                }
            }
        } else {
            setInputValue('');
            setSelectedName('');
        }
    }, [props.selected]);

    useEffect(() => {
        setOptions(props.options);
        setSelected(null);
    }, [props.options]);

    useEffect(() => {

        if (opened) {
            const name = props.optionName ? props.optionName : optionName;

            setOptions(props.options.filter(o => o[name].toLowerCase().includes(inputValue.toLowerCase())));
        } else {
            const name = props.optionName ? props.optionName : optionName;

            if (inputValue !== undefined) {
                setOptions(props.options.filter(o => o[name].toLowerCase().includes(inputValue.toLowerCase())));
            }
        }

        if (selected && inputValue === '') {
            setSelected(null);
        }

        if (inputValue !== '' && inputValue !== undefined) {
            const name = props.optionName ? props.optionName : optionName;
            const val = props.optionValue ? props.optionValue : optionValue;
            const idx = props.options.findIndex(o => o[name].toLowerCase() === inputValue.toLowerCase());

            if (idx !== -1) {
                setSelected(props.options[idx][val]);
                setSelectedName(props.options[idx][name]);
                onChange(props.options[idx][val]);
            }
        } else if (inputValue === '') {
            onChange('');
        }

    }, [inputValue]);

    useEffect(() => {

        if (!initialized && props.selected) {
            const value = props.optionValue ? props.optionValue : optionValue;
            const selectedOption = props.options.find(o => o[value] === props.selected);

            if (selectedOption !== undefined) {
                onChange(selectedOption[value]);
            } else {
                onChange('');
            }
            setInitialized(true);
        }

    }, [props.selected, inputValue, props.options]);

    useEffect(() => {
        // if (!opened) {
            setOptions(props.options);
        // }
    }, [opened]);

    useEffect(() => {
        setOptions(props.options);

        if (props.optionName) {
            setOptionName(props.optionName);
        }

        if (props.optionValue) {
            setOptionValue(props.optionValue);
        }
    }, []);

    const handleOptionSet = option => {

        if (props.multi) {
            const index = selectedList.indexOf(option[optionValue]);

            if (index > -1) {
                selectedList.splice(index, 1);
            } else {
                selectedList.push(option[optionValue]);
            }

            const newList = [...selectedList];
            setSelectedList(newList);
            onChange(newList);
        } else {
            setSelected(option[optionValue]);
            setSelectedName(option[optionName]);
            setInputValue(option[optionName]);
            onChange(option[optionValue]);
            setOpened(false);
        }
    };

    const checkOptionSelected = (optionValue) => {
        if (props.multi) {
            return selectedList.indexOf(optionValue) !== -1;
        } else {
            return selected === optionValue;
        }
    };

    let placeholder = '';

    if (props.multi) {
        if (selectedList.length === 0) {
            placeholder = 'sv.input_select.multiple_items_select';
        } else {
            placeholder = 'sv.input_select.multiple_items_selected';
        }
    } else if (!props.multi && props.placeholder) {
        placeholder = props.placeholder;
    }

    return(
        <>
            {props.label &&
            <>
                <div className="hidden lg:block">
                    <div className="inline-block w-1/2">
                        <Text left bold label={props.label} />
                    </div>
                    <div className="inline-block w-1/2">
                        <Text right>
                            {!props.notInForm &&
                                <ErrorMessage className="sv-red" name={props.name} component="div"/>
                            }
                        </Text>
                    </div>
                </div>
                <div className="block lg:hidden">
                    <Text left bold label={props.label} className="mt-10 mb-5" />
                </div>
            </>
            }
            <div className={'relative ' +
            (props.fullWidth ? 'w-full ' : '') +
            (opened && options.length > 1 ? 'border-0 ' : '') +
            (props.noBorders ? 'border-0 ' : '') +
            (props.search ? 'pl-0 ' : '') + (props.className ? props.className : '')
            } ref={wrapperRef}>
                <FormInput name={props.name} className="sv-input-select-dropdown-icon" onClick={() => setOpened(!opened)} value={inputValue}
                       noTopMargin={props.noTopMargin}
                       autoComplete="off"
                       onChange={e => handleInputValueChange(e.target.value)}
                       notInForm={props.notInForm}
                       noBorders={props.noBorders}
                       placeholder={placeholder}
                />
                <div className={'shadow-md rounded-md mt-1 z-50 absolute overflow-y-scroll bg-white w-full ' + (opened ? 'block ' : 'hidden ') + (props.search ? 'sv-input-select-options-search ' : '') + (props.smallHeight ? 'max-h-24' : 'max-h-48')}>
                    {options.map((option, idx) => (
                            <div key={idx} className={'py-2 pl-4 pr-2 relative h-10 border-b border-gray-100 cursor-pointer min-w-full hover:bg-sv-p-l hover:text-white ' + (checkOptionSelected(option[optionValue]) ? 'bg-sv-p-l text-white' : '')}
                                 onClick={() => handleOptionSet(option)}>
                                {option[optionName]}
                            </div>
                        )
                    )}
                </div>
            </div>
        </>
    );
};

InputSelect.protoTypes = {
    options: PropTypes.array.isRequired,
    multi: PropTypes.bool,
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    onChange: PropTypes.object,
    placeholder: PropTypes.string,
    selected: PropTypes.string,
    optionName: PropTypes.string.isRequired,
    optionValue: PropTypes.string.isRequired,
    search: PropTypes.bool,
    notInForm: PropTypes.bool,
    noBorders: PropTypes.bool,
    noTopMargin: PropTypes.bool,
};
