import PropTypes from 'prop-types';
import React from 'react';
import { Form, FormLabel } from './';
import { useField } from 'formik';
import * as Yup from 'yup';
import { FormattedMessage } from 'react-intl';
import { TrashIcon } from '@heroicons/react/outline';
import { CheckCircleIcon } from '@heroicons/react/solid';


export const FormInputList = props => {

    const [field, meta] = useField(props.name);
    const initValues = {item: ''};

    const inputFields = [
        {name: 'item', type: 'text', placeholder: props.inputPlaceholder, label: props.inputLabel}
    ];

    const validationSchema = {
        item: Yup.string()
            .required(<FormattedMessage id="sv.validation.required"/>)
    };

    const handleSubmit = (values, actions) => {
        const newValue = [...field.value];
        newValue.push(values.item);
        props.onChange(props.name, newValue, true);
        actions.resetForm();
    };

    const removeItem = idx => {
        const newValue = [...field.value];
        newValue.splice(idx, 1);
        props.onChange(props.name, newValue, true);
    };

    return (
        <div>
            <FormLabel label={props.label} name={props.name} />
            <div className="rounded shadow-sm border p-3">
                {field.value.map((input, idx) => (
                        <div key={props.key + '_' + idx} className="flex mt-3">
                            <CheckCircleIcon className="w-5 h-6 text-green-500 mr-3 flex-none" />
                            <div className="break-all">{input}</div>
                            <div className="flex-grow flex flex-row-reverse">
                                <TrashIcon className="w-5 h-6 ml-5 text-gray-500 cursor-pointer hover:text-sv-p-d"
                                           onClick={() => removeItem(idx)} />
                            </div>
                        </div>
                    )
                )}
                <Form
                    className="mt-3"
                    enableReinitialize={true}
                    initValues={initValues}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                    fields={inputFields}
                    noSubmitButton
                    submitBtnSmall
                    submitBtnSecondary
                    submitBtnLabel="sv.btn.add"
                />
            </div>
        </div>
    );
};

FormInputList.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    key: PropTypes.number.isRequired,
    onChange: PropTypes.func.isRequired,
    inputPlaceholder: PropTypes.string.isRequired,
    inputLabel: PropTypes.string.isRequired,
    meta: PropTypes.object.isRequired
};

