import React from 'react';
import { FormCategoryFilter, QuestionnaireInput } from '../components';
import PropTypes from 'prop-types';


export const QuestionnaireForm = React.memo(({...props}) => {

    const deleteOption = (idx) => {
        let quizConfig = props.quizConfig;
        if(props.quizConfig.lastSelectedIdx === idx){
            quizConfig.lastSelectedIdx = -1;
        }
        quizConfig.options.splice(idx, 1);
        props.updateForm(quizConfig, props.idx);
    }

    const newOption = () => {
        let quizConfig = props.quizConfig;
        quizConfig.options.push({label: {en: '', ru: '', lv: ''}, correct: false, selected: false, preEdit: false});
        props.updateForm(quizConfig, props.idx);
    }

    const updateOptionLabel = (isQuestion, label, idx = -1) => {
        let quizConfig = props.quizConfig;
        if (isQuestion) {
            quizConfig.question[props.lang] = label;
        } else if (idx !== -1) {
            quizConfig.options[idx].label[props.lang] = label;
        }
        quizConfig.options = [...quizConfig.options];
        props.updateForm({...quizConfig}, props.idx);
    }

    const onOptionSelect = (idx) => {
        let quizConfig = {...props.quizConfig}
        quizConfig.options[idx].selected = !quizConfig.options[idx].selected;
        if(props.editMode === true){
            quizConfig.options[idx].correct = !quizConfig.options[idx].correct;
        }
        if(quizConfig.multiselect === false && idx !== props.quizConfig.lastSelectedIdx) {
                let options = [...quizConfig.options];
                if(props.quizConfig.lastSelectedIdx === -1){
                    options[idx].selected = true;
                    if(props.editMode === true){
                        quizConfig.options[idx].correct = true;
                    }
                } else {
                    options[props.quizConfig.lastSelectedIdx].selected = false;
                    if(props.editMode === true){
                        quizConfig.options[props.quizConfig.lastSelectedIdx].correct = false;
                    }
                }
                quizConfig.options = options;
        }
        quizConfig.lastSelectedIdx = idx;
        props.updateForm(quizConfig, props.idx);
    }

    return (
        <div>
            {props.alphaMode &&
                <div className='w-full'>
                    <QuestionnaireSettings quizConfig={props.quizConfig} updateForm={props.updateForm} changeOrder={props.changeOrder} idx={props.idx}/>
                </div>
            }
            <div className={'table w-full text-center ' + (props.alphaMode ? 'border-t-0 border border-black border-dashed border-sv-p-l' : '')}>
                <div className='px-6 table-row'>
                    <div className='w-3/4 md:w-4/5 xl:w-6/8 min-h-28 h-auto m-auto mt-12 text-2xl'>
                        <QuestionnaireInput editMode={props.editMode}
                                            isQuestion={true}
                                            label={props.quizConfig.question[props.lang]}
                                            deleteBtn={false}
                                            updateOptionLabel={updateOptionLabel}
                                            key={new Date().getTime()}
                                            />
                    </div>
                </div>
                {props.quizConfig.options.map((option, idx) => (
                    <div className='table-row' key={idx}>
                        <div className='w-64 md:w-96 min-h-24 m-auto mt-8 text-xl'>
                            <QuestionnaireInput {...option}
                                                label={option.label[props.lang]}
                                                categoriesSelectedFunc={props.categoriesSelectedFunc}
                                                onOptionSelect={onOptionSelect}
                                                editMode={props.editMode}
                                                deleteBtn={props.quizConfig.options.length > 1}
                                                deleteOption={deleteOption}
                                                updateOptionLabel={updateOptionLabel}
                                                qIdx = {props.idx}
                                                idx={idx} key={option.label[props.lang] + idx}
                            />
                        </div>
                    </div>)
                )}
                <div className='table-row h-28'>
                    {props.editMode &&
                        <button className='text-green-400 hover:text-green-500 text-8xl'
                                onClick={() => newOption()}>+
                        </button>
                    }
                </div>
            </div>
        </div>
    );
}, (prevProps, nextProps) => {
    return prevProps.quizConfig === nextProps.quizConfig &&
           prevProps.optionsLength === nextProps.optionsLength &&
           prevProps.editMode === nextProps.editMode &&
           prevProps.lang === nextProps.lang
});

QuestionnaireForm.protoTypes = {
    quizConfig: PropTypes.shape({
        question: PropTypes.shape({ // Question label
                lv: PropTypes.string,
                ru: PropTypes.string,
                en: PropTypes.string
            }
        ),
        editMode: PropTypes.string,            // If alphaMode then needs editMode.isRequired
        multiselect: PropTypes.bool.isRequired,// Multiselect
        lastSelectedIdx: PropTypes.number.isRequired,           //Keeping in mind the last selected option for unselecting
        lastSelectedPreEditIdx: PropTypes.number.isRequired,    //Keeping in mind the last selected option for preview mode
        options: PropTypes.shape({
            label: PropTypes.shape({ // Question label
                lv: PropTypes.string,
                ru: PropTypes.string,
                en: PropTypes.string
            }),     // Option label
            correct: PropTypes.bool.isRequired,     // Is option correct?
            selected: PropTypes.bool.isRequired,    // Is option selected?
            preEdit: PropTypes.bool,     // Keeping in mind the last option configuration before preview mode
        })
    }).isRequired,                     // Main quiz configuration
    idx: PropTypes.string.isRequired,           // No. of the quiz
    editMode: PropTypes.bool.isRequired,        // Editing options
    updateForm: PropTypes.func.isRequired,      // Function that updates the 'list' of quizConfigs
    optionsLength: PropTypes.func.isRequired,   // for react.memo
    alphaMode: PropTypes.bool,                  // Show QuestionnaireSettings
    changeOrder: PropTypes.func,                 // Change quiz order
    lang: PropTypes.string
};



// The questionnaire settings bar at top of each form
export const QuestionnaireSettings = (props) => {
    const initValues = {multiselect: props.quizConfig.multiselect, preview: props.quizConfig.editMode, order: props.idx + 1 || ''};

    const inputFields = [
        {type: 'checkbox', name: 'multiselect', placeholder: 'sv.placeholder.email', label: 'sv.questionnaire_form.multi_select', labelPosition: 'left', labelWidth: 'w-auto'},
        {type: 'checkbox', name: 'preview', placeholder: 'sv.placeholder.email', label: 'sv.questionnaire_form.edit_mode', labelPosition: 'left', labelWidth: 'w-auto'},
        {type: 'number', name: 'order', placeholder: 'sv.placeholder.email', label: 'sv.questionnaire_form.order', labelPosition: 'left', labelWidth: 'w-auto'}
    ];

    const onChange = (e) => {
        if(e.target.name === 'preview'){
            let quizConfig = props.quizConfig;
            quizConfig.editMode = !quizConfig.editMode;
            if(e.target.checked === false){
                props.quizConfig.options.forEach((el, idx) => {
                    quizConfig.options[idx].preEdit = el.selected;;
                });
                quizConfig.lastSelectedPreEditIdx = props.quizConfig.lastSelectedIdx;
            } else {
                quizConfig.options.forEach(el => {
                    el.selected = el.preEdit;
                    el.correct = el.preEdit;
                });
                quizConfig.lastSelectedIdx = props.quizConfig.lastSelectedPreEditIdx;
            }
            props.updateForm(quizConfig, props.idx)
        } else if(e.target.name === 'multiselect'){
            let quizConfig = {...props.quizConfig}
            quizConfig.options.forEach(op => {
                op.selected = false;
                op.correct = false;
            });
            quizConfig.multiselect = e.target.checked;
            props.updateForm(quizConfig, props.idx);
        } else if (e.target.name === 'order'){
            e.target.addEventListener('blur', onOrderBlur);;
        }
    }

    const onOrderBlur = (e) => {
        props.changeOrder(props.idx, e.target.value - 1)
        e.target.removeEventListener('blur', onOrderBlur)
    }

    return (
        <FormCategoryFilter fields={inputFields} initValues={initValues} onChange={onChange}
                        inputClassName='mr-5 text-white h-68' className='bg-sv-p-l py-1'/>
    )
}

QuestionnaireSettings.protoTypes = {
    updateForm: PropTypes.func.isRequired,
    changeOrder: PropTypes.func.isRequired,
    idx: PropTypes.string.isRequired
};
