import moment from 'moment';


export function formatDate(dateToFormat, withTime = false) {

    if (dateToFormat === null || !dateToFormat) {
        return '-';
    }

    const date = moment(dateToFormat).local();

    if (!withTime) {
        return date.format('DD.MM.YYYY');
    }

    return moment(dateToFormat).local().format('DD.MM.YYYY HH:mm');
}

export function formatNumber(number, round=false) {

    if (number === undefined || number === null) {
        return '0';
    }

    if (round) {
        number = (Math.round(parseFloat(number) * 100) / 100).toFixed(2);
    }

    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}