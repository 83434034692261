import { chatConstants } from '../_constants';


export const chatActions = {
    addMessage,
    removeMessage
};

function addMessage(message) {
    return dispatch => {
        dispatch(success({message}));
    };

    function success(data) { return { type: chatConstants.ADD_MESSAGE, data } }
}

function removeMessage(messageId) {
    return dispatch => {
        dispatch(success({messageId}));
    };

    function success(data) { return { type: chatConstants.REMOVE_MESSAGE, data } }
}
