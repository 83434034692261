import { consultationConstants } from '../_constants';


export const consultationActions = {
    setPartnerSignal,
    setCallStarted,
    setCallFinished
};

function setPartnerSignal(signal) {
    return dispatch => {
        dispatch(success({signal}));
    };

    function success(data) { return { type: consultationConstants.SET_PARTNER_SIGNAL, data } }
}

function setCallStarted(consultationId) {
    return dispatch => {
        dispatch(success({consultationId}));
    };

    function success(data) { return { type: consultationConstants.SET_CALL_STARTED, data } }
}

function setCallFinished(data) {
    return dispatch => {
        dispatch(success(data));
    };

    function success(data) { return { type: consultationConstants.SET_CALL_FINISHED, data } }
}
