import React from 'react';
import { Text } from './Text';
import { history } from '../_library';


export const NewsPostPreview = props => (
    <div className="w-full rounded border mr-10 min-h-40 flex p-5 items-center cursor-pointer" onClick={() => history.push(`/news/${props._id}`)}>
        <div className="flex-none w-40 mr-10">
            <img src={props.image} className="h-full w-full object-cover rounded" />
        </div>
        <div>
            <Text left className="text-xl">{props.title}</Text>
            <div className="mt-5 text-sm" dangerouslySetInnerHTML={{__html: props.text.substr(0, 900) + '...'}} />
        </div>
    </div>
);
