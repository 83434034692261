import React, { useEffect, useRef, useState } from 'react';


export const QuestionnaireChoose = ({setQuestion, ...props}) => {
    const flexBox = useRef({});
    const [cheatCols, setCheatCols] = useState([]);

    useEffect(() => {
        calculateCheatCols();
        window.addEventListener('resize', calculateCheatCols);
        return () => {
            window.removeEventListener('resize', calculateCheatCols);
        }
    }, [])

    // For the columns to stack nicely to the left =>
    // => this function creates empty "boxes" to fill the leftover space
    const calculateCheatCols = () => {
        const num = Math.floor((flexBox.current.offsetWidth + 16) / 48);
        const temp = [];

        if (num === (num - (props.amount % num))){
            setCheatCols(temp);
            return;
        }

        for (let i = 0; i < num - (props.amount % num); i++) {
            temp.push(
                <button key={i} disabled={true} className='w-8 h-8 pointer-events-none'>
                </button>)
        }
        setCheatCols(temp);
    }

    const setSelectedIdx = (idx) => {
        setQuestion(idx);
    }

    return (
        <div className='border border-gray-400 bg-white rounded-sm'>
            <div ref={flexBox} className='flex justify-center flex-wrap gap-4 bg-white m-4'>
                {Array.from(Array(props.amount), (e, i) => {
                    return <ChooseBoxButton idx={i} key={i} selected={i === props.selected} selectedAnswersArr={props.selectedAnswersArr} setSelectedIdx={setSelectedIdx}/>
                })}
                {cheatCols}
            </div>
        </div>
    )
}

export const ChooseBoxButton = (props) => (
    <button className={'inline-block w-8 h-8 rounded-sm border-gray-400 align-middle bg-white ' +
    (props.selected ? 'border-2 border-blue-500 ' : 'border ') +
    (props.selectedAnswersArr && props.selectedAnswersArr[props.idx].includes(true) ? 'bg-blue-200 ' : '') } onClick={() => props.setSelectedIdx(props.idx)} >
        {props.idx+1}
    </button>
)
