import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useField } from 'formik';
import { FormInputWrapper } from './';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

export const FormEditor = props => {

    const [text, setText] = useState('');
    const [meta] = useField(props.name);

    const editorModules = {
        toolbar: [
            [{ 'header': [1, 2, false] }],
            ['bold', 'italic', 'underline','strike', 'blockquote'],
            [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
            ['link', 'image', 'video'],
            ['clean']
        ]
    };

    useEffect(() => {
        props.onChange(props.name, text, true);
    }, [text]);

    useEffect(() => {
        setText(props.initValue);
    }, [props.initValue]);

    return(
        <div>
            <FormInputWrapper className={props.className} width={props.width} meta={meta} name={props.name}
                              label={props.label} labelPosition={props.labelPosition} labelWidth={props.labelWidth}>
                <div className="rounded shadow-sm border p-3">
                    <ReactQuill value={text} onChange={setText} modules={editorModules} />
                </div>
            </FormInputWrapper>
        </div>
    );
};

FormEditor.propTypes = {
    name: PropTypes.string.isRequired,
    value: PropTypes.string,
    label: PropTypes.string,
    labelPosition: PropTypes.string,
    className: PropTypes.string
};
