import React, { useState, useEffect } from 'react';
import { StarIcon as StarIconOutline } from '@heroicons/react/outline';
import { StarIcon } from '@heroicons/react/solid';
import PropTypes from 'prop-types';


export const RatingStars = props => {

    const [rate, setRate] = useState(0);

    const handleClick = idx => {
        if (props.disabled) {
            return;
        }

        setRate(idx + 1);
        props.onChange(idx + 1);
    };

    useEffect(() => {
        if (props.rate) {
            setRate(props.rate);
        }
    }, [props.rate]);

    return (
        <div className={'h-10 w-full flex ' + (props.className ? props.className : '')}>
            {Array.from({ length: props.size}, (i, idx) => (
                    <React.Fragment key={idx}>
                        {idx < rate &&
                            <StarIcon className={'w-8 h-8 text-sv-s mr-3 ' + (!props.disabled ? 'cursor-pointer' : '')} onClick={() => handleClick(idx)} />
                        }
                        {idx >= rate &&
                            <StarIconOutline className={'w-8 h-8 text-sv-s mr-3 ' + (!props.disabled ? 'cursor-pointer' : '')} onClick={() => handleClick(idx)} />
                        }
                    </React.Fragment>
                )
            )}
        </div>
    );
};

RatingStars.protoTypes = {
    size: PropTypes.number.isRequired,
    rate: PropTypes.number,
    disabled: PropTypes.bool,
    onChange: PropTypes.func,
    className: PropTypes.string
};
